import { API, Auth } from 'aws-amplify';
import { generateHash } from '../../../assets/scripts/utilsLib';
/**
 * Creates a basic post and saves to the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const createBasePost = async (appId, socialGroupId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the post from the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const deletePost = async (appId, socialGroupId, postId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Deletes the social media master post from the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} masterPostId socialPostCode of the master post to remove
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const deleteMasterPost = async (appId, socialGroupId, masterPostId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/master/${masterPostId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets the details of the post
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const getPost = async (appId, socialGroupId, postId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets the details of the master post
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} masterPostId unquie id for the share to remove
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const getMasterPost = async (appId, socialGroupId, masterPostId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/master/${masterPostId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Add a new social media post to the selected profiles
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @param {object} data post object
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const savePost = async (appId, socialGroupId, postId, data, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}`, {
            headers: {
                Authorization: token
            },
            body: {
                identitys: data.identitys,
                message: data.message,
                scheduledPostDate: data.scheduledPostDate,
                activityType: data.activityType,
                postStatus: data.postStatus,
                shareUrl: data.shareUrl,
                mediaUrl: data.mediaUrl,
                media: { ...data.media },
                remoteSource: { ...data.remoteSource },
                albumId: data.albumId,
                tags: data.tags,
                contentTypes: data.contentType
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Saves a master social media post to the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} masterPostId socialPostCode of the share to save
 * @param {object} data post object
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const saveMasterPost = async (appId, socialGroupId, masterPostId, data, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/master/${masterPostId}`, {
            headers: {
                Authorization: token
            },
            body: {
                identitys: data.identitys,
                message: data.message,
                scheduledPostDate: data.scheduledPostDate,
                activityType: data.activityType,
                postStatus: data.postStatus,
                shareUrl: data.shareUrl,
                mediaUrl: data.mediaUrl,
                media: { ...data.media },
                remoteSource: { ...data.remoteSource },
                albumId: data.albumId,
                tags: data.tags
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Send the selected media file to S3
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} socialPostCode post id of the share to upload the image to
 * @param {File} file file object to upload
 * @param {object} fileInfo file metadata
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise containing the upload response
 */
export const uploadMasterMedia = async (appId, socialGroupId, socialPostCode, file, fileInfo, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        const extension = fileInfo.type.split('/')[1];

        // greater than 10 MB
        if (fileInfo.size >= 10485760) {
            const hash = await generateHash(file);

            return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${socialPostCode}/media/signed`, {
                headers: {
                    Authorization: token
                },
                'body': {
                    'contentType': fileInfo.type,
                    'extension': extension,
                    'height': fileInfo.height,
                    'width': fileInfo.width,
                    'size': fileInfo.size,
                    'format': fileInfo.format,
                    'fileName': fileInfo.name,
                    'hash': hash
                }
            })
                .then(async result => {
                    // console.log(`Body: ${JSON.stringify(result.body)}`);
                    let payload = {
                        'method': 'PUT',
                        'headers': {
                            'content-type': fileInfo.type,
                            'content-length': file.size
                        },
                        'body': file
                    };
                    // console.log(payload);
                    return await fetch(result.body.uploadURL, payload)
                        .then(response => {
                            // console.log(response);
                            return {
                                status: response.statusText,
                                body: {
                                    publicFilePath: result.body.publicFilePath,
                                    width: fileInfo.width,
                                    height: fileInfo.height,
                                    contentType: fileInfo.type,
                                    size: fileInfo.size
                                }
                            };
                        });
                });
        } else {
            // base64 encode the file then upload to the API Gateway
            const base64Data = await readUploadedFile(file)
                .catch(err => {
                    console.error(err);
                    return null;
                });

            return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/master/${socialPostCode}/media`, {
                headers: {
                    Authorization: token
                },
                isBase64Encoded: true,
                body: {
                    file: base64Data
                }
            });
        }
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Send the selected media file to S3
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {File} file file object to upload
 * @param {object} fileInfo file metadata
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise containing the upload response
 */
export const uploadMedia = async (appId, socialGroupId, postId, file, fileInfo, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        const extension = fileInfo.type.split('/')[1];

        // greater than 10 MB
        if (fileInfo.size >= 10485760) {
            const hash = await generateHash(file);

            return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}/media/signed`, {
                headers: {
                    Authorization: token
                },
                'body': {
                    'contentType': fileInfo.type,
                    'extension': extension,
                    'height': fileInfo.height,
                    'width': fileInfo.width,
                    'size': fileInfo.size,
                    'format': fileInfo.format,
                    'fileName': fileInfo.name,
                    'hash': hash
                }
            })
                .then(async result => {
                    // console.log(`Body: ${JSON.stringify(result.body)}`);
                    let payload = {
                        'method': 'PUT',
                        'headers': {
                            'content-type': fileInfo.type,
                            'content-length': file.size
                        },
                        'body': file
                    };
                    // console.log(payload);
                    return await fetch(result.body.uploadURL, payload)
                        .then(response => {
                            // console.log(response);
                            return {
                                status: response.statusText,
                                body: {
                                    publicFilePath: result.body.publicFilePath,
                                    width: fileInfo.width,
                                    height: fileInfo.height,
                                    contentType: fileInfo.type,
                                    size: fileInfo.size
                                }
                            };
                        });
                });
        } else {
            // base64 encode the file then upload to the API Gateway
            const base64Data = await readUploadedFile(file)
                .catch(err => {
                    console.error(err);
                    return null;
                });

            return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}/media`, {
                headers: {
                    Authorization: token
                },
                isBase64Encoded: true,
                body: {
                    file: base64Data
                }
            });
        }
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Delete a given media file from the associated S3 bucket
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} socialPostCode post id of the share to upload the image to
 * @param {string} key name of media item to delete
 * @param {*} token [token] AWS amplify user token
 * @returns {void}
 */
export const deleteMasterMedia = async (appId, socialGroupId, socialPostCode, key, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/master/${socialPostCode}/media/${key}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Delete a given media file from the associated S3 bucket
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {string} key name of media item to delete
 * @param {*} token [token] AWS amplify user token
 * @returns {void}
 */
export const deleteMedia = async (appId, socialGroupId, postId, key, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}/media/${key}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Queries the remote url and returns a list of images and their respective content
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {object} url valid url to search
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const getRemoteUrl = async (appId, socialGroupId, postId, url, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}/remote?url=${encodeURIComponent(url)}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Moves the local S3 object to a permanent location
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId post id of the share to upload the image to
 * @param {string} url local url (S3 key) or object selected
 * @param {object} remoteSourceObject object containing the remote source details to save
 * @param {boolean} isMaster whether this is a master post or not
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const selectRemoteImage = async (appId, socialGroupId, postId, url, remoteSourceObject, isMaster, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}/remote`, {
            headers: {
                Authorization: token
            },
            body: {
                isMaster: isMaster,
                key: url,
                sourceTitle: remoteSourceObject.sourceTitle,
                sourceUrl: remoteSourceObject.sourceUrl,
                sourceAttribution: remoteSourceObject.sourceAttribution,
                sourceAttributionUrl: remoteSourceObject.sourceAttributionUrl,
                sourceImageUrl: remoteSourceObject.sourceImageUrl,
                sourceDescription: remoteSourceObject.sourceDescription
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Change the scheduled post date
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId ID of the post to reschedule
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const copyPost = async (appId, socialGroupId, postId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}/copy`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of posts for the given month
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} date date filter in the format of '2020-10' (YYYY-MM)
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const getPostsByScheduledDate = async (appId, socialGroupId, date, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/list?keyOperator=begins_with&keyFilterBy=scheduledPostDate&keyValue=${date}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of posts for the given month
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} date date filter in the format of '2020-10' (YYYY-MM)
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const getPostsByDate = async (appId, socialGroupId, date, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/list?keyOperator=begins_with&keyFilterBy=postDate&keyValue=${date}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of posts matching the post status given
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} status post status to filter for
 * @param {string} key partition or primary key name
 * @param {string} sort sort by key
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const getPostsByStatus = async (appId, socialGroupId, status, key, sort, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        if (!sort) {
            sort = 'addedAt';
        }

        return API.get('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/list?keyFilterBy=${key}&keyOperator=begins_with&sortBy=${sort}&filterBy=postStatus&filterValue=${status}&operator=eq`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Add a new social media post to the selected profiles
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} postId unquie id for the share to remove
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const rePost = async (appId, socialGroupId, postId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/post/${postId}/repost`, {
            headers: {
                Authorization: token
            },
            body: {}
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};


/**
 * Read the input file and transform into a buffer array
 * @param {string} inputFile base64 encoded image file
 * @returns {Promise<ArrayBuffer|DOMException>} promise containing the buffer array of the image OR and Error
 */
const readUploadedFile = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(inputFile);
    });
};
