import React from 'react';
import '../../assets/styles/app.scss';

/**
 * Page not found response
 * @returns {HTMLElement} html for page not found block
 */
export default function NotFound() {
    return (
        <div className='NotFound'>
            <h3>Sorry, page not found!</h3>
        </div>
    );
}
