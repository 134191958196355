import { API, Auth } from 'aws-amplify';

/**
 * Gets a list of applications the current user has access to
 * @param {string} [filter] app filter
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise which resolves a list of application objects
 */
export const getApplications = async (filter, token) => {
    try {
        // check if a token was provided but no filter, if so, assume filter is the token
        if (!token && (filter && filter.match(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/))) {
            token = filter;
        }

        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        let url = '/app/list';

        if (filter) {
            url = url + '?operator=contains&filterBy=name&filterValue=' + filter;
        }

        return API.get('stitchz-web-api', url, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Save the application to the database
 * @typedef {{
 * applicationId: number
 * name: string
 * description: string
 * url: string
 * privacyUrl: string
 * subscriptionType: number
 * termsAccepted: boolean
 * }} ApplicationModel
 * @param {ApplicationModel} app application to create
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise which resolves to new or updated application object
 */
export const saveApplication = async (app, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        if (app.applicationId) {
            return API.put('stitchz-web-api', `/app/${app.applicationId}`, {
                headers: {
                    Authorization: token
                },
                body: app
            });
        } else {
            return API.post('stitchz-web-api', '/app/create', {
                headers: {
                    Authorization: token
                },
                body: app
            });
        }
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Get the application's getails
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise with application info
 */
export const getApplicationDetails = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the current application from the database
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const deleteApplication = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of applications the current user has access to
 * @param {string} filter app filter
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise which resolves a list of application objects
 */
export const getConfigApplications = async (filter, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        let url = '/config/apps';

        if (filter) {
            url = url + '?filter=name&value=' + filter;
        }

        return API.get('stitchz-web-api', url, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Get the application's getails
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise with application info
 */
export const getConfigApplicationDetails = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/config/app/${appId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
