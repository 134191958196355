import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { ClipContainer as PostIdentityCardContainer } from './PostIdentityCardContainer';
import { PostIdentityCard } from './PostIdentityCard';
import config from '../../../config';

/**
 * Identity cards list page
 * @param {object} argv object defining properties to use in this function
 * @param {object} argv.post post object
 * @param {object} argv.socialGroup social group object
 * @param {Function} argv.onSetIdentity function to set the state of the identity object
 * @param {Function} argv.onClickAddIdentity function to execute when adding a new identity
 * @param {Function} argv.onClickRenewPageToken function to execute when renewing a Page's token
 * @param {boolean} argv.isRefreshing whether or not the profile is being refreshed
 * @param {Function} argv.onChange function to execute when something is changed
 * @param {Function} argv.onSelectProfile function to execute when a profile is selected in the UI
 * @param {Function} argv.onChangeOrder function to execute when order is changed
 * @param {object} argv.identity user's identity object
 * @returns {HTMLElement} html for identity cards list block
 */
export function PostIdentityCards({
    post,
    socialGroup,
    onSetIdentity,
    onClickAddIdentity,
    onClickRenewPageToken,
    isRefreshing,
    onChange,
    onSelectProfile,
    onChangeOrder,
    identity
}) {

    let identityCards = [];

    if (identity && post.providerId) {
        const prov = config.providers.find(p => { return p.id === Number(post.providerId) });

        identityCards.push(
            <PostIdentityCard
                key={identity.id}
                post={post}
                identity={identity}
                onSetIdentity={onSetIdentity}
                provider={prov}
                onClickAddIdentity={onClickAddIdentity}
                onClickRenewPageToken={onClickRenewPageToken}
                isRefreshing={isRefreshing}
                onChange={onChange}
                onSelectProfile={() => { }}
                forceActive={true}
            />
        );
    }
    else if (socialGroup && socialGroup.socialGroupIdentitys && socialGroup.socialGroupIdentitys.length > 0) {
        // eslint-disable-next-line
        socialGroup.socialGroupIdentitys.map((m, idx) => {
            // console.log(m.order + ' - ' + m.displayName);
            const prov = config.providers.find(p => { return p.id === Number(m.providerId) });
            m.image = !m.image ? config.USERICON : m.image;

            // only display choices from IdPs that allow social sharing
            if (prov.socialCapabilities.split(',').some(item => ['text', 'link', 'photo', 'audio', 'video'].includes(item))) {
                // some IdP do not allow to post with personal account (i.e. Facebook), so exclude those from the list
                if ([1].indexOf(m.providerId) === -1
                    || [1].indexOf(m.providerId) > -1 && m.parentIdentityId) {
                    // console.log(idx);
                    // add html to array
                    identityCards.push(
                        <PostIdentityCardContainer
                            key={m.id}
                            identity={identity || m}
                            onChangeOrder={onChangeOrder}
                            isFirst={idx <= 1}
                            isLast={idx >= socialGroup.socialGroupIdentitys.length - 1}
                        >
                            <PostIdentityCard
                                post={post}
                                identity={identity ? m.id === identity.id ? identity : m : m}
                                onSetIdentity={onSetIdentity}
                                provider={prov}
                                onClickAddIdentity={onClickAddIdentity}
                                onClickRenewPageToken={onClickRenewPageToken}
                                isRefreshing={isRefreshing}
                                onChange={onChange}
                                onSelectProfile={onSelectProfile}
                            />
                        </PostIdentityCardContainer>
                    );
                }
            }
        });
    } else if (socialGroup) {
        const key = new Date().getTime();
        identityCards.push(
            <Alert variant='warning' key={key}>
                <Alert.Heading>No Social Identities Found</Alert.Heading>
                <p className='mb-0'>
                    You must have at least one Identity associated with this
                    Social Group. Go to the <strong>{socialGroup.name}</strong> <Alert.Link href={`/app/${post.applicationId}/social/${post.socialGroupId}`}>Dashboard</Alert.Link>&nbsp;
                    to add one now.
                </p>
            </Alert>
        );
    } // else UNKNOWN ERRROR!!!!

    return (
        <Row>
            <Col>
                {identityCards}
            </Col>
        </Row>
    );
}
