import React from 'react';

/**
 * Builds the UI for an overlay layer
 * @param {string} color background color for the overlay
 * @returns {HTMLElement} html
 */
export function OverlayBackdrop ({color}) {

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 1,
                opacity: 0.5,
                backgroundColor: color
            }}
        />
    );
}
