import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../../components/Loading';
import { LoaderButton } from '../../../../components/LoaderButton';
import { PostsByWeek } from '../../../components/Social/Calendar/PostsByWeek';
import { Calendar } from '../../../../assets/scripts/calendarLib';
import { formatDate, add, getWeekInMonth } from '../../../../assets/scripts/dateLib';
import '../../../../assets/styles/calendar.scss';

/**
 * Builds the current date's calendar items for the week in scope
 * @param {object} argv object defining properties to use in this function
 * @param {Array} argv.calendarPosts collection of posts for this week
 * @param {object} argv.socialGroup social group associated with these posts
 * @param {boolean} argv.pageLoading whether or not the master page is loading
 * @param {boolean} argv.isLoading whether or not the function is running
 * @param {object} argv.weekDate current date in scope
 * @param {Function} argv.setWeekDate function to update the weekDate
 * @param {Function} argv.onClickEdit ...
 * @param {Function} argv.onChangeEdit function to track/update the state upon text field changes
 * @param {Function} argv.onClickDelete function to control the deletion of the post
 * @param {Function} argv.onClickReschedule function to control rescheduling the post
 * @param {Function} argv.onClickCopy function to control the copying of the post
 * @param {Function} argv.onClickCreatePost function to trigger a post creation
 * @param {boolean} argv.isAllowedToPost whether or not this application is allowed to post a share
 * @param {Function} argv.onMoveReschedulePost triggered when the post is moved in a calendar
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {object} argv.router routing object history
 * @param {boolean} argv.isMoving whether or not the post is scheduling days
 * @returns {HTMLElement} html
 */
export function Week({
    calendarPosts,
    socialGroup,
    pageLoading,
    isLoading,

    weekDate, setWeekDate,

    onClickEdit,
    onChangeEdit,
    onClickDelete,
    onClickReschedule,
    onClickCopy,
    onClickCreatePost,
    isAllowedToPost,
    canEdit,
    router,
    onMoveReschedulePost,
    isMoving
}) {

    let currentDate = weekDate ? weekDate.startOfCalendar : null,
        targetDate = weekDate ? weekDate.targetDate : null,
        weeksPosts = targetDate ? calendarPosts[getWeekInMonth(targetDate) - 1] : [];

    /**
     * Resets the navigation to the current date and resets the weekDate state
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const goToToday = (e) => {
        e.preventDefault();

        const now = new Date();

        setWeekDate(new Calendar(now.getFullYear(), now.getMonth(), now.getDate()));

        // router.push('calendar#week');
        router.history(`/${router.query.id}/social/${socialGroup.id}/calendar#week`);
    };

    /**
     * Navigates the UI to the previous week's calendar
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onMovePrev = (e) => {
        e.preventDefault();

        const targetDate = add(weekDate.startOfCalendar, -1, 'weeks');

        setWeekDate(new Calendar(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate()));

        // router.push(getPrevWeekUrl());
        router.history(`/${router.query.id}/social/${socialGroup.id}/calendar${getPrevWeekUrl()}`);
    }

    /**
     * Navigates the UI to the next week's calendar
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onMoveNext = (e) => {
        e.preventDefault();

        const targetDate = add(weekDate.startOfCalendar, 1, 'weeks');

        setWeekDate(new Calendar(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate()));

        // router.push(getNextWeekUrl());
        router.history(`/${router.query.id}/social/${socialGroup.id}/calendar${getNextWeekUrl()}`);
    }

    /**
     * Build the link to the previous week's calendar
     * @returns {string} link to previous week
     */
    const getPrevWeekUrl = () => {
        return `?week=${weekDate.prevWeek}#week`;
    };

    /**
     * Build the link to the next week's calendar
     * @returns {string} link to next week
     */
    const getNextWeekUrl = () => {
        return `?week=${weekDate.nextWeek}#week`;
    };

    /**
     * Builds and displays html button if the user has permissions to edit
     * @returns {HTMLElement|void} html button element or nothing
     */
    const allowedToPost = () => {
        if (isAllowedToPost) {
            return (
                <LoaderButton
                    variant='primary'
                    isLoading={isLoading}
                    onClick={onClickCreatePost}>
                    Create Post
                </LoaderButton>
            );
        }
    };

    return (
        pageLoading ?
            <Loading pageLoading={pageLoading} /> :
            <Container>
                <Row>
                    <Col sm={12} md={9}>
                        <div className='page-header'>
                            <h1><FontAwesomeIcon icon={faCalendarWeek} />&nbsp;Week</h1>
                        </div>
                    </Col>
                    <Col md='auto'>
                        <Button
                            onClick={goToToday}
                        >
                            Today
                        </Button>
                    </Col>
                    <Col sm={12} md='auto'>
                        {allowedToPost()}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <td className='center'><Link to={getPrevWeekUrl()} onClick={onMovePrev}>&lt;</Link></td>
                                    <td colSpan='5' className='center'>
                                        <h3>{`${formatDate(weekDate.targetDate, 'MMMM')} ${formatDate(weekDate.targetDate, 'yyyy')}`}</h3>
                                    </td>
                                    <td className='center'><Link to={getNextWeekUrl()} onClick={onMoveNext}>&gt;</Link></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sun</td>
                                    <td>Mon</td>
                                    <td>Tues</td>
                                    <td>Wed</td>
                                    <td>Thur</td>
                                    <td>Fri</td>
                                    <td>Sat</td>
                                </tr>

                                <tr>
                                    {weeksPosts &&
                                        <PostsByWeek
                                            weeksDays={weeksPosts.days}
                                            appId={router.query.id}
                                            grpId={socialGroup.id}
                                            socialGroupIdentitys={socialGroup.socialGroupIdentitys}
                                            targetDate={targetDate}
                                            currentDate={currentDate}
                                            referer={encodeURIComponent(`${router.pathname}${router.location.search}${router.location.hash}`)}
                                            canEdit={canEdit}
                                            onClickEdit={onClickEdit}
                                            onChangeEdit={onChangeEdit}
                                            onClickDelete={onClickDelete}
                                            onClickReschedule={onClickReschedule}
                                            onClickCopy={onClickCopy}
                                            onClickCreatePost={onClickCreatePost}
                                            onMoveReschedulePost={onMoveReschedulePost}
                                            isMoving={isMoving}
                                        />
                                    }
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
    );
}
