import React, { useRef, forwardRef } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faClock } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.min.css';
import '../../../../assets/styles/react-datepicker/datepicker.scss'

/**
 * Builds and displays an html for the calendar
 * @param {object} argv object defining properties to use in this function
 * @param {string} argv.displayDate date value
 * @param {string} argv.displayTime time value
 * @param {Date} argv.calendarDate date object
 * @param {Function} argv.onChangeScheduledPostDate function to update the post date
 * @param {Function} argv.onChangeScheduledPostTime function to update the post time
 * @param {boolean} argv.dateHasFocus if the date field should have focus
 * @param {Function} argv.setDateHasFocus function to set focus on date field
 * @param {boolean} argv.timeHasFocus if the time field should have focus
 * @param {Function} argv.setTimeHasFocus function to set focus on time field
 * @returns {HTMLElement} html
 */
export function DateTimePicker ({
    displayDate,
    displayTime,
    calendarDate,
    onChangeScheduledPostDate,
    onChangeScheduledPostTime,
    dateHasFocus,
    setDateHasFocus,
    timeHasFocus,
    setTimeHasFocus
}) {

    const refCustomInput = useRef(),
        // assisted by https://github.com/Hacker0x01/react-datepicker/issues/2051
        BootstrapCustomDateInput = forwardRef((props, ref) => { // eslint-disable-line react/no-multi-comp
            const { autoFocus, ...remaining} = props; // eslint-disable-line no-unused-vars
            return (
                <Form.Group controlId='scheduledPostDate'>
                    <InputGroup className='mb-3'>
                        <Form.Control
                            value={displayDate}
                            type='text'
                            onChange={onChangeScheduledPostDate}
                            ref={ref}
                            autoFocus={dateHasFocus}
                            {...remaining}
                        />
                        <InputGroup.Text onClick={props.onClick}><FontAwesomeIcon icon={faCalendarDay} /></InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            );
        });

    // eslint looks for this
    BootstrapCustomDateInput.displayName = 'BootstrapCustomDateInput';

    const BootstrapCustomTimeInput = forwardRef((props, ref) => { // eslint-disable-line react/no-multi-comp
        const { autoFocus, ...remaining} = props; // eslint-disable-line no-unused-vars

        return (
            <>
                <Form.Group controlId='scheduledPostDate'>
                    <InputGroup className='mb-3'>
                        <Form.Control
                            value={displayTime}
                            type='text'
                            onChange={onChangeScheduledPostTime}
                            ref={ref}
                            autoFocus={timeHasFocus}
                            {...remaining}
                        />
                        <InputGroup.Text onClick={props.onClick}><FontAwesomeIcon icon={faClock} /></InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </>
        );
    });

    // eslint looks for this
    BootstrapCustomTimeInput.displayName = 'BootstrapCustomTimeInput';

    return (
        <>
            <Row>
                <Col>
                    Schedule <FontAwesomeIcon icon={faCalendarDay} />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DatePicker
                        selected={calendarDate}
                        onChange={date => onChangeScheduledPostDate(date)}
                        // maxDate={addYears(new Date(), 2)}
                        dateFormat='dd-MMM-yyyy'
                        customInput={<BootstrapCustomDateInput ref={refCustomInput} />}
                        onFocus={evt => { // eslint-disable-line no-unused-vars
                            setDateHasFocus(true);
                        }}
                        onBlur={evt => { // eslint-disable-line no-unused-vars
                            setDateHasFocus(false);
                        }}
                    />
                </Col>
                <Col sm={6}>
                    <DatePicker
                        selected={calendarDate}
                        onChange={date => onChangeScheduledPostTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption='Time'
                        dateFormat='HH:mm'
                        customInput={<BootstrapCustomTimeInput ref={refCustomInput} />}
                        onFocus={evt => { // eslint-disable-line no-unused-vars
                            setTimeHasFocus(true);
                        }}
                        onBlur={evt => { // eslint-disable-line no-unused-vars
                            setTimeHasFocus(false);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}
