import React, { useEffect, useState, Suspense } from 'react';
import { Container, Row, Col, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faKey, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getApplications } from '../../app/libs/Api/application';
import { removeMyAccount, getMyAccount } from '../../app/libs/Api/users';
import { Loading } from '../../components/Loading';
import { LoaderButton } from '../../components/LoaderButton';
import { Apps as AppList } from '../components/Apps';
import { useToggle, useRouter, useFormFields } from '../../assets/scripts/hooksLib';
import { adjustHeader } from '../../assets/scripts/utilsLib';
import { formatDate } from '../../assets/scripts/dateLib';
import { onError } from '../../assets/scripts/errorLib';
// import '../../assets/styles/app.scss';
// import '../../assets/styles/admin.scss';
import '../../assets/styles/settings.scss';

const ChangePassword = React.lazy(() => import('../components/ChangePassword'));
const UpdateUser = React.lazy(() => import('../components/UpdateUser'));

/**
 * User profile page
 * @returns {HTMLElement} html for the user profile block
 */
export default function Index() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [apps, setApps] = useState([]);
    const [appSearch, setAppSearch] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [showUpdateUserForm, setShowUpdateUserForm] = useToggle(false);
    const [userFields, handleUserFieldsChange] = useFormFields({
        email: '',
        code: ''
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const [isConfirming, setIsConfirming] = useToggle(false);
    const [showPwdChgForm, setShowPwdChgForm] = useToggle(false);
    const [passwordFields, handlePasswordFieldsChange] = useFormFields({
        password: '',
        oldPassword: '',
        confirmPassword: ''
    });
    const [isChanging, setIsChanging] = useState(false);

    // confirm current user is authorized to be here
    useEffect(() => {
        let isMounted = true;

        // async function onLoad() {
        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getMyAccount()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setUser(body);
                }

                setPageLoading(false);
            } catch (e) {
                console.log(e);
            }
        }
        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setPageLoading]);

    // load apps list
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const { status, message, body } = await getApplications()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setApps(body.Items);


                }
            } catch (e) {
                onError(e);
            } finally {
                //
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [setApps]);

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    /**
     * Handles the onChange event for user search
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onSearchAppChange = (e) => {
        const val = e.target.value;

        setAppSearch(val);
    };

    /**
     * Searches the app list by name keyword
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const searchByAppName = async (e) => {
        if (e) {
            e.preventDefault();
        }

        try {
            const { status, message, body } = await getApplications(appSearch)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                setApps(body.Items);
            }
        } catch (e) {
            onError(e);
        } finally {
            //
        }

        return;
    };

    /**
     * Show/Hide update user modal form
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleUpdateUser = async (e) => {
        if (e) {
            e.preventDefault();
        }

        showPwdChgForm ? setShowUpdateUserForm(false) : setShowUpdateUserForm(true);
        return;
    };

    /**
     * Checks and confirms the form fields are complete
     * @returns {boolean} true is complete, or false is incomplete
     */
    const validateUpdateUserForm = () => {
        return (
            userFields.email.length > 0
        );
    };

    /**
     * Checks there is a confirmation code given
     * @returns {boolean} true is complete, or false is incomplete
     */
    const validateConfirmForm = () => {
        return (
            userFields.code.length > 0
        );
    };

    /**
     * Changes the user's email address and sends confirmation code - ** CURRENTLY NOT POSSIBLE (19-Jan-2021) **
     * @param {MouseEvent|KeyboardEvent} e mouse click or Enter key pressed
     * @returns {void}
     */
    const handleUpdateUserClick = async (e) => {
        e.preventDefault();

        setIsUpdating(true);

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, { email: userFields.email });

            setCodeSent(true);
        } catch (error) {
            console.log('here');
            onError(error);
            setIsUpdating(false);
        }
    };

    /**
     * Confirms the user's email address via their confirmation code
     * @param {MouseEvent|KeyboardEvent} e mouse click or Enter key pressed
     * @returns {void}
     */
    const handleConfirmClick = async (e) => {
        e.preventDefault();

        setIsConfirming(true);

        try {
            await Auth.verifyCurrentUserAttributeSubmit('email', userFields.code);

            // router.push('/me');
            router.history('/me');
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    };

    /**
     * Resends a confirmation code to the user
     * @param {MouseEvent|KeyboardEvent} e mouse click or Enter key pressed
     * @returns {void}
     */
    const handleResendConfirmClick = async (e) => {
        e.preventDefault();

        const y = window.confirm('To confirm your email address you must request and enter a code. Would you like to request a confirmation code?');

        if (y) {
            setIsConfirming(true);

            try {
                await Auth.verifyCurrentUserAttribute('email');

                setCodeSent(true);
                setShowUpdateUserForm(true);
            } catch (error) {
                onError(error);
                setIsConfirming(false);
            }
        }
    };

    /**
     * Show/Hide change password modal
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleChangePassword = async (e) => {
        if (e) {
            e.preventDefault();
        }

        showPwdChgForm ? setShowPwdChgForm(false) : setShowPwdChgForm(true);
        return;
    };

    /**
     * Checks and confirms the form fields are complete
     * @returns {boolean} true is complete, or false is incomplete
     */
    const validateChangePasswordForm = () => {
        return (
            passwordFields.oldPassword.length > 0 &&
            passwordFields.password.length > 0 &&
            passwordFields.password === passwordFields.confirmPassword
        );
    };

    /**
     * Changes the user's password credentials and redirects if valid
     * @param {MouseEvent|KeyboardEvent} e mouse click or Enter key pressed
     * @returns {void}
     */
    const handleChangePasswordClick = async (e) => {
        e.preventDefault();

        setIsChanging(true);

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
                currentUser,
                passwordFields.oldPassword,
                passwordFields.password
            );

            // router.push('/me');
            router.history('/me');
        } catch (error) {
            onError(error);
            setIsChanging(false);
        }
    };

    /**
     * Prompt to delete this user account
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const removeUserAccount = async (e) => {
        if (e) {
            e.preventDefault();
        }

        const y = window.confirm('ARE YOU SURE YOU WANT TO PERMANENTLY DELETE YOUR USER ACCOUNT?\n\rTHIS IS PERMANENT AND CANNOT BE REVERESED, DO YOU WISH TO CONTINUE?');

        if (y) {
            try {
                const { status, message } = await removeMyAccount()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    router.history('/');
                }
            } catch (e) {
                onError(e);
            } finally {
                //
            }
        }

        return;
    };


    const getUserAttributeValue = (user, field) => {
        const prop = user.UserAttributes.find(a => {
            return a.Name === field;
        });

        return prop.Value
    };

    return (
        <div className='App'>
            {pageLoading ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <ChangePassword
                            showChangePasswordForm={showPwdChgForm}
                            handleClosePasswordForm={toggleChangePassword}
                            fields={passwordFields}
                            handleFieldChange={handlePasswordFieldsChange}
                            isChanging={isChanging}
                            validateChangePasswordForm={validateChangePasswordForm}
                            handleChangePasswordClick={handleChangePasswordClick}
                        />
                    </Suspense>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <UpdateUser
                            showChangeForm={showUpdateUserForm}
                            handleCloseForm={toggleUpdateUser}
                            fields={userFields}
                            handleFieldChange={handleUserFieldsChange}
                            isUpdating={isUpdating}
                            validateChangeForm={validateUpdateUserForm}
                            handleChangeClick={handleUpdateUserClick}
                            codeSent={codeSent}
                            isConfirming={isConfirming}
                            validateConfirmForm={validateConfirmForm}
                            handleConfirmClick={handleConfirmClick}
                        />
                    </Suspense>
                    <h1>User Details</h1>

                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col className='smaller-label'>Name</Col>
                            </Row>
                            <Row>
                                <Col className='large-field-value'>{getUserAttributeValue(user, 'name')}</Col>
                            </Row>
                            <Row>
                                <Col className='smaller-label'>Email</Col>
                            </Row>
                            <Row>
                                <Col className='smaller-field-value'>{getUserAttributeValue(user, 'email')}</Col>
                            </Row>
                            <Row>
                                <Col md={6} className='smaller-label'>Status {user.UserStatus === 'CONFIRMED' ? <span className='success'><FontAwesomeIcon icon={faCheck} /></span> : ''}</Col>
                                <Col md={6} className='smaller-label'>Email Verified {getUserAttributeValue(user, 'email_verified') === 'true' ? <span className='success'><FontAwesomeIcon icon={faCheck} /></span> : ''}</Col>
                            </Row>
                            <Row>
                                <Col md={6} className='smaller-field-value'>{user.UserStatus === 'CONFIRMED' ? '' : user.UserStatus}</Col>
                                <Col md={6} className='smaller-field-value'>
                                    {getUserAttributeValue(user, 'email_verified') === 'false' ?
                                        <Button
                                            size='sm'
                                            variant='info'
                                            onClick={handleResendConfirmClick}
                                        >
                                            <FontAwesomeIcon icon={faCheckDouble} />&nbsp;Send Confirmation Code
                                        </Button>
                                        :
                                        ''
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <span className='smaller-x2-label'>Created</span> <span className='smaller-field-value'>{formatDate(user.UserCreateDate, 'dd-MMM-yyyy HH:mm')}</span>
                                </Col>
                                <Col md={6}>
                                    <span className='smaller-x2-label'>Updated</span> <span className='smaller-field-value'>{formatDate(user.UserLastModifiedDate, 'dd-MMM-yyyy HH:mm')}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <ButtonToolbar
                                        className='justify-content-between'
                                        aria-label='Toolbar with Button groups'
                                    >
                                        <ButtonGroup>
                                            {/* <Button
                                                size='sm'
                                                variant='success'
                                                onClick={toggleUpdateUser}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />&nbsp;Update
                                            </Button> */}
                                            <Button
                                                size='sm'
                                                variant='warning'
                                                onClick={toggleChangePassword}
                                            >
                                                <FontAwesomeIcon icon={faKey} />&nbsp;Change Password
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Col>
                                <Col md={6} className='float-right'>
                                    <LoaderButton
                                        size='sm'
                                        variant='danger'
                                        onClick={removeUserAccount}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />&nbsp;Remove Account
                                    </LoaderButton>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <AppList
                                appList={apps}
                                keyword={appSearch}
                                onKeywordChange={onSearchAppChange}
                                onSearch={searchByAppName}
                                currentUser={user}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            &nbsp;
                        </Col>
                        <Col md={6}>
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    );
}