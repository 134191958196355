import React, { useState, useEffect, Suspense, ReactComponentElement } from 'react';
import { Form, Container, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { Elements, SetupIntent } from '@stripe/react-stripe-js';
import { saveApplication } from '../../libs/Api/application';
import { getCountryList, saveCustomerDetails } from '../../libs/Api/billing';
import { Loading } from '../../../components/Loading';
import { LoaderButton } from '../../../components/LoaderButton';
import { useFormFields, useRouter } from '../../../assets/scripts/hooksLib';
import { adjustHeader, scrollTop } from '../../../assets/scripts/utilsLib';
import { onError } from '../../../assets/scripts/errorLib';
import config from '../../../config';
// import '../../../assets/styles/app.scss';

const PricingTable = React.lazy(() => import('../../components/NewApp/PricingTable'));
const BillingForm = React.lazy(() => import('../../components/NewApp/BillingForm'));
const ApplicationForm = React.lazy(() => import('../../components/NewApp/ApplicationForm'));

/**
 * New Application form
 * @returns {HTMLElement} html for application form block
 */
export function NewApp() {
    const router = useRouter();
    const [stripe, setStripe] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [app, setApp] = useState({
        name: '',
        description: '',
        url: '',
        privacyUrl: '',
        subscriptionType: 9999,
        termsAccepted: false
    });
    const [fields, handleFieldChange] = useFormFields({
        name: '',
        // email: '',
        address_line1: '',
        state: '',
        city: '',
        postal_code: '',
        country: ''
    });
    const [billingUser, setBillingUser] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const steps = [
        {
            step: 1,
            text: 'Step 1: Create Application',
            subheader: 'Create Your Application',
            description: 'An application allows your to organize your social media accounts into groups, as well as a place to organize your information.'
        },
        {
            step: 2,
            text: 'Step 2: Subscription Plan',
            subheader: 'Choose a Subscription Plan',
            description: 'Select from three flexible plans to meet your needs. The first 30 days is free!'
        },
        {
            step: 3,
            text: 'Step 3: Billing Info',
            subheader: 'Owner and Card Holder Info',
            description: 'This is the primary contact and card holder for this application and is required for billing purposes.'
        },
        {
            step: 4,
            text: 'Step 4: Payment Info',
            subheader: 'Enter Your Payment Info',
            description: 'Credit card details are securely transmitted to our trusted third party Stripe.com for processing. No details are ever stored with us.',
            disclaimer: <span>* Please refer to Stripe.com&apos;s <a href='https://stripe.com/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a> for more info</span>
        },
        {
            step: 5,
            text: 'Step 5: Review',
            subheader: 'Double Check and Get Started',
            description: 'Confirm the application information, subscription and payment info are correct, then click &lsquot;Complete Purchase&rsquot;.'
        }
    ];
    const [stepTitle, setStepTitle] = useState(steps[0].text);

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // load billing user info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            const user = await Auth.currentAuthenticatedUser()
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.message
                    };
                });

            if (!user) {
                onError('There was an error loading your credentials. Please reload the page to try again.');
            } else {
                setBillingUser({
                    username: user.username,
                    email: user.attributes.email,
                    name: user.attributes.name,
                    token: user.signInUserSession.accessToken.jwtToken
                });
            }
        };

        if (isMounted) {
            adjustHeader();

            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [setBillingUser]);

    // load stripe
    useEffect(() => {
        // dynamically add the Stripe JS to the page
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.async = true;
        script.setAttribute('id', 'stripe-js');
        document.body.appendChild(script);

        if (window.Stripe) {
            setStripe(window.Stripe(config.STRIPE_KEY));
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                setStripe(window.Stripe(config.STRIPE_KEY));
            });
        }

        // clean up the Stripe JS script when we leave
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // load stripe country list
    useEffect(() => {
        let isMounted = true;

        const stripeCountryList = async () => {
            try {
                const { status, message, body } = await getCountryList()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    if (body) {
                        setCountryList(body);
                    }
                }
            } catch (err) {
                onError(err);
            } finally {
                setIsLoading(false);
            }
        }

        if (isMounted) {
            stripeCountryList()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    /******************************************************/
    /* 1. New Application functions                       */
    /******************************************************/
    /**
     * Checks the application details info is complete
     * @returns {boolean} true|false
     */
    const validateForm = () => {
        return app.name && app.name.length > 0;
    };

    /**
     * Handles the state of the application object
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChange = (e) => {
        const field = e.target.id;
        const val = e.target.value;

        setApp(app => {
            return { ...app, [field]: val }
        });
    };
    /******************************************************/

    /******************************************************/
    /* 2. Subscription plan form                          */
    /******************************************************/
    /**
     * Checks the subscription details info is complete
     * @returns {boolean} true|false
     */
    const validateSubscriptionForm = () => {
        return app.subscriptionType > 0 && app.subscriptionType <= config.subscriptionTypes.reduce((prev, current) => prev.y > current.y ? prev : current).id;
    };

    /**
     * Handles the state of the application object; specifically the subscription plan type
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onSubscriptionChange = (e) => {
        if (e.target.type === 'radio') {
            if (!isNaN(e.target.value)) {
                const field = 'subscriptionType',
                    val = e.target.value;

                setApp(app => {
                    return { ...app, [field]: val }
                });
            }
        }
    };

    /**
     * Builds out the subscription pricing table
     * @returns {HTMLElement} html pricing table elements
     */
    const renderSubscriptionForm = () => {
        let subscriptionSelection = [<option key='0' />];

        Object.keys(config.subscriptionTypes).forEach(key => {
            subscriptionSelection.push(<option key={config.subscriptionTypes[`${key}`].id} value={config.subscriptionTypes[`${key}`].id}>{config.subscriptionTypes[`${key}`].name}</option>)
        });

        return (
            <>
                <Suspense fallback={<Loading pageLoading={true} />}>
                    <PricingTable subscription={app.subscriptionType} onclick={onSubscriptionChange} />
                </Suspense>
                <ButtonToolbar
                    className='justify-content-between'
                    aria-label='Toolbar with Button groups'
                >
                    <Button
                        onClick={prevStep}
                    >
                        &lt; Previous
                    </Button>
                    <LoaderButton
                        variant='success'
                        isLoading={isLoading}
                        disabled={!validateSubscriptionForm()}
                        onClick={nextStep}
                    >
                        {app.subscriptionType && Number(app.subscriptionType) === config.subscriptionTypes.reduce((prev, current) => prev.y > current.y ? prev : current).id ?
                            <>Review</>
                            :
                            <>Next &gt;</>
                        }
                    </LoaderButton>
                    <Button
                        variant='secondary'
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </ButtonToolbar>
            </>
        );
    };
    /******************************************************/

    /******************************************************/
    /* 3. Customer Details form and functions (Billing Info)*/
    /******************************************************/
    /**
     * Checks the customer billing details info is complete
     * @returns {boolean} true|false
     */
    const validateCustomerDetailsForm = () => {
        return (
            fields.name !== '' &&
            fields.address_line1 !== '' &&
            fields.city !== '' &&
            fields.state !== '' &&
            fields.country !== ''
        );
    };

    /**
     * Saves and creates a new customer at stripe, updates the state, and moves to next step
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const handleCustomerDetailsSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        // create customer, setupIntent and return client secret on server side
        try {
            const { status, message, body } = await saveCustomerDetails({
                userId: billingUser.username,
                name: fields.name,
                email: billingUser.email,
                address: fields.address_line1,
                city: fields.city,
                state: fields.state,
                postal: fields.postal_code,
                country: fields.country,
                subscriptionCustomerId: customer ? customer.customer : app.subscriptionCustomerId
            })
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                setCustomer(body);

                // move to next step
                // setCurrentStep(currentStep + 1);
                nextStep();
            }
        } catch (err) {
            onError(err);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Builds and displays a customer details form
     * @returns {HTMLElement|void} html form or nothing
     */
    const renderCustomerDetailsForm = () => {
        if (Number(app.subscriptionType) === config.subscriptionTypes.reduce((prev, current) => prev.y > current.y ? prev : current).id) {
            setCurrentStep(2);
        } else {
            return (
                <>
                    <Form.Group controlId='name'>
                        <Form.Label>Cardholder&apos;s name</Form.Label>
                        <Form.Control
                            type='text'
                            value={fields.name}
                            onChange={handleFieldChange}
                            placeholder='Name on the card'
                        />
                    </Form.Group>
                    <Form.Group controlId='address_line1'>
                        <Form.Label>Cardholder&apos;s Address</Form.Label>
                        <Form.Control
                            type='text'
                            value={fields.address_line1}
                            onChange={handleFieldChange}
                            placeholder='Street Address'
                        />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group controlId='city'>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={fields.city}
                                    onChange={handleFieldChange}
                                    placeholder='City'
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId='state'>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={fields.state}
                                    onChange={handleFieldChange}
                                    placeholder='State, Province or Region'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId='country'>
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    as='select'
                                    value={fields.country}
                                    onChange={handleFieldChange}
                                >
                                    {countryList && countryList.map(c => {
                                        return <option key={c}>{c}</option>
                                    })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <ButtonToolbar
                        className='justify-content-between'
                        aria-label='Toolbar with Button groups'
                    >
                        <Button
                            onClick={prevStep}
                        >
                            &lt; Previous
                        </Button>
                        <LoaderButton
                            variant='success'
                            isLoading={isLoading}
                            disabled={!validateCustomerDetailsForm()}
                            onClick={handleCustomerDetailsSubmit}
                        >
                            Next &gt;
                        </LoaderButton>
                        <Button
                            variant='secondary'
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </>
            );
        }
    };
    /******************************************************/

    /******************************************************/
    /* 4. Credit card details form (Payment Info)         */
    /******************************************************/
    /**
     * Setup the users credit card in preparation for subscribing to the service
     * @param {SetupIntent} setupIntent intent object used to create payment method at stripe
     * @param {Function} [cleanup] function to run any cleanup before unmouting a component
     * @returns {void}
     */
    const onBillingSubmit = (setupIntent, cleanup) => {

        setIsLoading(true);

        try {
            // save the customer id to the new app
            setApp(app => {
                return { ...app, 'subscriptionCustomerId': customer.customer, 'subscriptionPaymentId': setupIntent.payment_method }
            });

            // run any remaining/outstanding functions before the component unmounts
            if (cleanup) {
                cleanup();
            }

            // move to next step
            // setCurrentStep(currentStep + 1);
            nextStep();
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Builds and displays the stripe credit card details form
     * @returns {HTMLElement|void} html form or nothing
     */
    const renderCardDetailsForm = () => {
        if (!customer) {
            setCurrentStep(2); // move to the subscription plan selection form
        } else {
            return (
                <Elements stripe={stripe}>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <BillingForm
                            fields={fields}
                            isLoading={isLoading}
                            subscription={app.subscriptionType}
                            secret={customer.client_secret}
                            onSubmit={onBillingSubmit}
                            prevStep={prevStep}
                            onCancel={onCancel}
                        />
                    </Suspense>
                </Elements>
            );
        }
    };
    /******************************************************/

    /******************************************************/
    /* 5. Review application & billing details            */
    /******************************************************/
    /**
     * Checks that the terms have been acknowledged and accepted
     * @returns {boolean} true|false
     */
    const validateReviewForm = () => {
        return app.termsAccepted;
    };

    /**
     * Handles the state of the application object; specifically the terms accepted
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onTermsAcceptanceChange = (e) => {
        if (e.target.type === 'checkbox') {
            const field = 'termsAccepted',
                val = e.target.checked;

            setApp(app => {
                return { ...app, [field]: val }
            });
        }
    };

    /**
     * Save the application details, create a new app, and redirect to the details page
     * @param {MouseEvent} e mouse click event
     * @returns {void} redirects to new application page
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const { status, message, body } = await saveApplication(app)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // url = resp.headers.location;
                const url = `/${body.applicationId}`;

                // router.push(url);
                router.history(url);
            }
        } catch (e) {
            setIsLoading(false);
            onError(e);
        } finally {
            //
        }
    };

    /**
     * Builds and displays the overall review page
     * @returns {ReactComponentElement} html review form
     */
    const renderReview = () => {
        // get the subscription object
        const subscr = app && config.subscriptionTypes[app.subscriptionType > 0 && app.subscriptionType < 6 ? app.subscriptionType - 1 : 0];

        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Col md={2} className='label'>Name</Col>
                            <Col md={10}><strong>{app.name}</strong></Col>
                        </Row>
                        <Row>
                            <Col md={2} className='label'>Description</Col>
                            <Col md={10}><strong>{app.description ? app.description : '-'}</strong></Col>
                        </Row>
                        <Row>
                            <Col md={2} className='label'>Plan</Col>
                            <Col md={10}>
                                <strong>{subscr.name} - ${subscr.price}/month</strong>
                                <br />
                                <small>{subscr.description}</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} className='label'>Last 4 Digits</Col>
                            <Col md={10}><strong>.... { }</strong></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='text-center cushion'>
                    <Col>
                        <Form.Group>
                            <Form.Check
                                required
                                name='terms'
                                label='Agree to terms and conditions'
                                onChange={onTermsAcceptanceChange}
                                id='termsAccepted'
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LoaderButton
                            block='true'
                            variant='success'
                            type='submit'
                            disabled={!validateReviewForm()}
                            isLoading={isLoading}
                            onSubmit={handleSubmit}
                        >
                            Complete Purchase
                        </LoaderButton>
                    </Col>
                </Row>
            </>
        );
    };
    /******************************************************/

    /******************************************************/
    /* Navigation functions                               */
    /******************************************************/
    /**
     * Handles the state of the current navigation step, incrementing by one
     * @param {KeyEvent} [e] key press event
     * @returns {void}
     */
    const nextStep = (e) => {
        if (e) e.preventDefault();

        const s = steps[`${currentStep}`];
        setStepTitle(s.text);

        // skip to the last step if the user selected the free service
        if (currentStep < 5) {
            if (Number(app.subscriptionType) === config.subscriptionTypes.reduce((prev, current) => prev.y > current.y ? prev : current).id) {
                setCurrentStep(steps.length);
            } else {
                setCurrentStep(currentStep + 1);
            }
        }

        scrollTop();
    };

    /**
     * Handles the state of the current navigation step, jumping directly to a step
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const goToStep = (e) => {
        e.preventDefault();

        let step = e.target.dataset.step;

        if (!isNaN(step)) {
            step = Number(step);

            const s = steps[`${step}`];
            setStepTitle(s.text);

            if (step >= 0 && step <= 5) {
                setCurrentStep(step + 1);
            }

            scrollTop();
        }
    };

    /**
     * Handles the state of the current navigation step, decrementing by one
     * @param {KeyEvent} [e] key press event
     * @returns {void}
     */
    const prevStep = (e) => {
        if (e) e.preventDefault();

        if (currentStep > 1) {
            const s = steps[currentStep - 1];
            setStepTitle(s.text);
            setCurrentStep(currentStep - 1);

            scrollTop();
        }
    };

    /**
     * Builds and displays the navigation pane
     * @returns {HTMLElement} html block of navigation
     */
    const renderNavStep = () => {
        let stepsList = [],
            content = '';

        // loop over the steps array and build out the navigation
        for (var i = 0; i < steps.length; i++) {
            if (steps[`${i}`].step <= currentStep) {
                content = <div className='jumbotron'>
                    <h3>{steps[`${i}`].subheader}</h3>
                    <p>{steps[`${i}`].description}</p>
                    {steps[`${i}`].disclaimer ?
                        <p className='smaller-x2'>{steps[`${i}`].disclaimer}</p>
                        : ''
                    }
                </div>;

                stepsList.push(
                    <Row key={i}>
                        <Col>
                            <a href='/new#' data-step={i} onClick={goToStep}>
                                {steps[`${i}`].text}
                            </a>
                        </Col>
                    </Row>
                );
            } else {
                stepsList.push(
                    <Row key={i}>
                        <Col>
                            {steps[`${i}`].text}
                        </Col>
                    </Row>
                );
            }
        }

        return <>
            {content}
            {stepsList}
        </>;
    };

    /**
     * Builds and displays the appropriate html based on the current setp
     * @returns {HTMLElement} html block
     */
    const renderStep = () => {
        switch (currentStep) {
        case 1:
            return <Suspense fallback={<Loading pageLoading={true} />}>
                <ApplicationForm
                    onChange={onChange}
                    onCancel={onCancel}
                    isLoading={isLoading}
                    validateForm={validateForm}
                    nextStep={nextStep}
                    app={app}
                />
            </Suspense>;
        case 2:
            return renderSubscriptionForm();
        case 3:
            return renderCustomerDetailsForm();
        case 4:
            return renderCardDetailsForm();
        case 5:
            return renderReview();
        default:
            return <Suspense fallback={<Loading pageLoading={true} />}>
                <ApplicationForm
                    onChange={onChange}
                    onCancel={onCancel}
                    isLoading={isLoading}
                    validateForm={validateForm}
                    nextStep={nextStep}
                    app={app}
                />
            </Suspense>;
        }
    };
    /******************************************************/

    /******************************************************/
    /* Shared & Helper functions                          */
    /******************************************************/
    /**
     * Cancels the new application creation process
     * @param {MouseEvent} e mouse click event
     * @returns {void} redirects to root home page
     */
    const onCancel = (e) => {
        e.preventDefault();

        // router.push('/');
        router.history('/');
    };
    /******************************************************/

    return (
        <div className='App'>
            <Container>
                <Row>
                    <Col md={3}>
                        {renderNavStep()}
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <h3>{stepTitle}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <form onSubmit={handleSubmit}>
                                            {renderStep()}
                                        </form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
