import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons'

/**
 * Builds and displays an html row for the calendar item
 * @param {string} name name of the fontawesome icon to display
 * @returns {HTMLElement} html
 */
export const Icon = ({name}) => {
    if (name === 'box')
    {
        return <span className='stitchz-fonts-box' />;
    }
    else if (name === 'salesforce')
    {
        return <span className='stitchz-fonts-salesforce' />;
    }
    else if (name === 'discogs')
    {
        return <span className='stitchz-fonts-discogs' />;
    }
    else if (name === 'disqus')
    {
        return <span className='stitchz-fonts-disqus' />;
    }
    else if (name === 'plurk')
    {
        return <span className='stitchz-fonts-plurk' />;
    }
    else
    {
        library.add(fab);

        if (name === 'biohazard')
        {
            return <FontAwesomeIcon icon={faBiohazard} />;
        }
        else
        {
            return <FontAwesomeIcon icon={['fab', name.toLowerCase()]} />;
        }
    }
};
