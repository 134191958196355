import { API, Auth } from 'aws-amplify';

/**
 * Begins the request to add a new Identity Profile to this Social Group
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {object} body identity request object, including state, email and provider Id
 * @param {string} [token] AWS amplify user token
 * @param {object} [user] user object making the request
 * @returns {Promise} http message response promise
 */
export const initiateNewIdentityRequest = async (appId, socialGroupId, body, token, user) => {
    try {
        // check if token exists and user doe not, if so, then assign user variable with token value
        if (!user && typeof token === 'object' || token instanceof Object) {
            user = token;
        }

        if (!token) {
            if (!user || user && !user.signInUserSession) {
                user = await Auth.currentAuthenticatedUser();
            }

            token = user.signInUserSession.accessToken.jwtToken;

            body.userId = user.username;
        }

        return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/identity`, {
            headers: {
                Authorization: token
            },
            body: body
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Saves the social group to this application to the database
 * @param {string} appId application id
 * @param {object} group social group object
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const saveSocialGroup = async (appId, group, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/social`, {
            headers: {
                Authorization: token
            },
            body: {
                id: group.id, // this will be null in new groups
                name: group.name
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the given social group from the application and updates the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const removeSocialGroup = async (appId, socialGroupId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/social/${socialGroupId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Update the given identity and saves to the database
 * @param {string} appId application id
 * @param {string} grpId social group id
 * @param {object} identity identity to update
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const updateIdentity = async (appId, grpId, identity, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/social/${grpId}/identity`, {
            headers: {
                Authorization: token
            },
            body: { ...identity }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Adds the given page and saves to the database
 * @param {string} appId application id
 * @param {string} grpId social group id
 * @param {object} identity identity to relate the page to
 * @param {object} page page identity properties
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const addPageCompanyIdentity = async (appId, grpId, identity, page, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.post('stitchz-web-api', `/app/${appId}/social/${grpId}/identity/${identity.id}/pages`, {
            headers: {
                Authorization: token
            },
            body: {
                identity: identity,
                page: page
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Remove the Identity Profile from this Social Group
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} identityId unique id of the identity to remove
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const removeIdentity = async (appId, socialGroupId, identityId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/identity/${identityId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of Pages, Companies, or Boards associated with the Identity Profile
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} identityId unique id of the identity
 * @param {number} providerId provider id of identity
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const getPagesCompaniesBoards = async (appId, socialGroupId, identityId, providerId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        let url;

        if (providerId === 1 || providerId === 29) {
            url = `/app/${appId}/social/${socialGroupId}/identity/${identityId}/pages`;
        } else if (providerId === 5) {
            url = `/app/${appId}/social/${socialGroupId}/identity/${identityId}/pages`;
        } else if (providerId === 27) {
            url = `/app/${appId}/social/${socialGroupId}/identity/${identityId}/boards`;
        }

        if (!url) {
            return Promise.reject('This provider is not allowed to request pages.');
        }

        return API.get('stitchz-web-api', url, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Uploads a binary video clip
 * @param {string} appId application id
 * @param {string} socialGroupId socialGroup id
 * @param {object} sourceIdentityId identity id of the identity to move
 * @param {string} destinationIdentityId identity id of the identity to replace
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const reorderIdentitys = async (appId, socialGroupId, sourceIdentityId, destinationIdentityId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.post('stitchz-web-api', `/app/${appId}/social/${socialGroupId}/identity/reorder`, {
            headers: {
                Authorization: token
            },
            body: {
                sourceIdentityId: sourceIdentityId,
                destinationIdentityId: destinationIdentityId
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
