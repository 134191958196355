import { add, clone, daysInMonth, getWeekday, setDayOfMonth, week, month, weeksInYear, getWeekInMonth } from './dateLib';

/**
 * Builds the necessary properties to represent a calendar month in the user's
 * local date time (local timezone)
 * @returns {object} calendar object
 */
class Calendar {
    constructor(year, month, day) {
        this.targetDate = new Date(year, month, day);
        this.now = new Date();

        this.previousMonth = add(clone(this.targetDate), -1, 'months');
        this.nextMonth = add(clone(this.targetDate), 1, 'months');
        this.daysInMonth = daysInMonth(this.targetDate);

        this.dayOfWeek = getWeekday(this.targetDate);
        this.startOfCalendar = add(clone(this.targetDate), -this.dayOfWeek, 'days');
        this.weeksInMonth = this.calcWeeksInMonth();
        this.currentWeekInMonth = getWeekInMonth(this.targetDate);
        this.isoWeek = week(this.targetDate);
        this.nextWeek = week(add(this.targetDate, 1, 'weeks'));
        this.prevWeek = week(add(this.targetDate, -1, 'weeks'));

        // console.log(`targetDate = ${this.targetDate}`);
        // console.log(`now = ${this.now}`);
        // console.log(`previousMonth = ${this.previousMonth}`);
        // console.log(`nextMonth = ${this.nextMonth}`);
        // console.log(`daysInMonth = ${this.daysInMonth}`);
        // console.log(`dayOfWeek = ${this.dayOfWeek}`);
        // console.log(`startOfCalendar = ${this.startOfCalendar}`);
        // console.log(`weeksInMonth = ${this.weeksInMonth}`);
    }

    resetStartOfCalendar = () => {
        this.startOfCalendar = add(clone(this.targetDate), -getWeekday(this.targetDate), 'days');
    };

    /**
     * Determines the number of weeks in the month for the target Date
     * @returns {number} number of weeks in the month
     */
    calcWeeksInMonth = () => {
        const dateFirst = setDayOfMonth(clone(this.targetDate), 1);
        const dateLast = setDayOfMonth(clone(this.targetDate), this.daysInMonth);
        const startWeek = week(dateFirst);
        const endWeek = week(dateLast);

        if (endWeek < startWeek) {
            // Yearly overlaps, month is either DEC or JAN
            if (month(dateFirst) === 0) {
                // January
                return endWeek + 1;
            } else {
                // December
                if (getWeekday(dateLast) === 7) {
                    // Sunday is last day of year
                    return endWeek - startWeek + 1;
                } else {
                    // Sunday is NOT last day of year
                    return weeksInYear(dateFirst) - startWeek + 1;
                }
            }
        } else {
            return endWeek - startWeek + 1;
        }
    };
}

export { Calendar };
