import { API, Auth } from 'aws-amplify';

/**
 * Saves and updates the member in the database
 * @param {string} appId application id
 * @param {object} mem member object from state
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const saveMember = async (appId, mem, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/member/${mem.userId}`, {
            headers: {
                Authorization: token
            },
            body: {
                permissionLevel: mem.permissionLevel,
                isOwner: mem.permissionLevel === 7 ? true : false
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Sends the member request and saves to the database
 * @param {string} appId application id
 * @param {object} memReq memberRequest object from state
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const initiateMemberRequest = async (appId, memReq, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/member/request`, {
            headers: {
                Authorization: token
            },
            body: {
                email: memReq.email,
                permissionLevel: memReq.permissionLevel,
                isOwner: memReq.permissionLevel === 7 ? true : false
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the request to add a new member from the application and updates the database
 * @param {string} appId application id
 * @param {string} reqId new member request id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const acceptMemberRequest = async (appId, reqId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/member/request/${reqId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the request to add a new member from the application and updates the database
 * @param {string} appId application id
 * @param {string} reqId new member request id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const cancelMemberRequest = async (appId, reqId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/member/request/${reqId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Remove the user from the application and updates the database
 * @param {string} appId application id
 * @param {string} memId user id of member to remove
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const removeMember = async (appId, memId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/member/${memId}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
