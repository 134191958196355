import React, { ReactComponentElement } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../Icon';
import { camelCase, pastTense, scrollTop } from '../../../../assets/scripts/utilsLib';
import { formatDate, getWeekInMonth, isBeforeDate, getWeekday } from '../../../../assets/scripts/dateLib';
import { OverlayMove } from './OverlayMove';
import config from '../../../../config';

/**
 * Builds and displays an html row for the calendar item
 * @param {object} argv object defining properties to use in this function
 * @param {object} argv.post unique id of the application
 * @param {object} argv.identity application provider object to update
 * @param {boolean} argv.showActions true or false to display action buttons
 * @param {Function} argv.onChange function to track/update the state upon text field changes
 * @param {Function} argv.onClickDelete function to control the deletion of the post
 * @param {Function} argv.onClickReschedule function to control rescheduling the post
 * @param {Function} argv.onClickCopy function to control the copying of the post
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {string} argv.query referring page
 * @param {boolean} argv.isMoving whether or not the post is scheduling days
 * @returns {HTMLElement} html
 */
export function PostItem({
    post,
    identity,
    showActions,
    onChange, // eslint-disable-line no-unused-vars
    onClickDelete,
    onClickReschedule,
    onClickCopy,
    canEdit,
    query,
    isMoving
}) {
    const postDate = post.postDate || post.scheduledPostDate, //getLocalDateTime(post.scheduledPostDate),
        now = new Date(),
        isPast = isBeforeDate(postDate, now) && post.postStatus === config.postStatus.POSTED ? true : false,
        [{ isDragging }, drag] = useDrag({ // eslint-disable-line no-unused-vars
            type: config.itemTypes.POST,
            item: { type: config.itemTypes.POST, postId: post.postId, week: getWeekInMonth(post.postDate || post.scheduledPostDate) - 1, day: getWeekday(post.postDate || post.scheduledPostDate) },
            collect: monitor => ({
                isDragging: !!monitor.isDragging()
            }),
            canDrag: () => { return canEdit }
            // end: (item, monitor) => {
            //     console.log(item);
            // }
        }),
        identityProvider = identity && identity.provider ? identity.provider : {
            name: 'unknown',
            iconName: 'biohazard',
            id: 0
        };

    /**
     * Display the remote image
     * @param {string} remoteImage url to image
     * @param {number} [height] display height of the image
     * @param {number} [width] display width of the image
     * @param {string} [title] title to display for the image
     * @returns {HTMLEmbedElement} html for displaying the image
     */
    const renderRemoteImage = (remoteImage, height, width, title) => {
        const hasTitle = title ? true : false;

        if (remoteImage && width && height) {
            return (
                <div className='card' style={{ padding: '0.5rem' }}>
                    <img src={`${remoteImage}?width=150`} width='150' height='150' alt='remote thumbnail' className='remote-image img-thumbnail card-img-top' />
                    <div className='card-body'>
                        <a href='#' className='close-local-image-content'>×</a>
                        {hasTitle ?
                            <p className='card-title' style={{ fontSize: '0.75rem' }}>{title}</p>
                            :
                            ''
                        }
                        <div className='card-text' style={{ fontSize: '0.525rem' }}>Image size: {width} x {height}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <img src={`${remoteImage}?width=150`} width='150' height='150' alt='remote thumbnail' className='remote-image img-thumbnail card-img-top' />
                    <a href='#' className='close-local-image-content'>×</a>
                </>
            );
        }
    };

    /**
     * Builds out the remote url source display
     * @param {object} remoteUrlSource url of remote page
     * @returns {HTMLElement|void} html to display the remote details or nothing
     */
    const renderRemoteSource = (remoteUrlSource) => {
        if (remoteUrlSource) {
            if (remoteUrlSource.sourceImages && remoteUrlSource.sourceImages.length > 0) {
                let rows = [];

                // loop over the source images and build out html
                remoteUrlSource.sourceImages.forEach(img => {
                    const hasLocalImageUrls = remoteUrlSource.localImageUrls[img.id] ? true : false;

                    rows.push(
                        <div className='row remote-link' key={img.id}>
                            <div className='small-4 columns remote-image-content'>
                                {hasLocalImageUrls ?
                                    <img src='~/Scripts/holder.js/150x150' width='150' height='150' alt='placeholder' className='remote-image' />
                                    :
                                    renderRemoteImage(`${remoteUrlSource.baseSiteUrl} ${remoteUrlSource.localImageUrls[img.id]}`, img.height, img.width, img.sourceImageTitle)
                                }
                            </div>
                            <div className='small-8 columns remote-link-content'>
                                <div className='remote-title'>{remoteUrlSource.sourceTitle}</div>
                                <div className='remote-url'><a href={remoteUrlSource.sourceUrl}>{remoteUrlSource.sourceUrl.Host}</a></div>
                                <div className='remote-body'>{remoteUrlSource.sourceDescription}</div>
                                <div>
                                    <input id='NoImage' name='NoImage' value='true' type='checkbox' />
                                    <label htmlFor='NoImage'>No Image</label>
                                    <input name='NoImage' value='false' type='hidden' />
                                </div>

                                <input type='hidden' name='ShareUrl' id='ShareUrl' value={remoteUrlSource.sourceUrl} />
                                <input type='hidden' name='MediaUrl' id='MediaUrl' value={img.sourceImageUrl} />

                                <input id='RemoteUrlSource_SourceAttribution' name='RemoteUrlSource.SourceAttribution' value={remoteUrlSource.sourceAttribution} type='hidden' />
                                <input id='RemoteUrlSource_SourceAttributionUrl' name='RemoteUrlSource.SourceAttributionUrl' value={remoteUrlSource.sourceAttributionUrl} type='hidden' />
                                <input id='RemoteUrlSource_SourceDescription' name='RemoteUrlSource.SourceDescription' value={remoteUrlSource.sourceDescription} type='hidden' />
                                <input id='RemoteUrlSource_SourceImageTitle' name='RemoteUrlSource.SourceImageTitle' value={remoteUrlSource.sourceImageTitle ? remoteUrlSource.sourceImageTitle : img.sourceImageTitle} type='hidden' />
                                <input id='RemoteUrlSource_SourceImageUrl' name='RemoteUrlSource.SourceImageUrl' value={remoteUrlSource.sourceImageUrl ? remoteUrlSource.SourceImageUrl : img.sourceImageUrl} type='hidden' />
                                <input id='RemoteUrlSource_SourceTitle' name='RemoteUrlSource.SourceTitle' value={remoteUrlSource.sourceTitle} type='hidden' />
                                <input id='RemoteUrlSource_SourceUrl' name='RemoteUrlSource.SourceUrl' value={remoteUrlSource.sourceUrl} type='hidden' />
                                <input id='RemoteUrlSource_LocalImageUrl' name='RemoteUrlSource.LocalImageUrl' value={`${remoteUrlSource.baseSiteUrl} ${remoteUrlSource.localImageUrls[img.id]}`} type='hidden' />

                            </div>
                        </div>
                    );
                });

                return rows;
            } else {
                const hasLocalImageUrls = remoteUrlSource.localImageUrls.length > 0 ? true : false;

                return (
                    <div className='remote-link smaller'>
                        <div className='remote-link-content'>
                            <div className='remote-title'>{remoteUrlSource.sourceTitle ? remoteUrlSource.sourceTitle : ''}</div>
                            <div className='remote-url'><a href={remoteUrlSource.SourceUrl}>{remoteUrlSource.sourceUrl ? remoteUrlSource.sourceUrl.Host : ''}</a></div>
                            <div className='remote-body'>{remoteUrlSource.sourceDescription ? remoteUrlSource.sourceDescription : ''}</div>
                            <input type='hidden' name='ShareUrl' id='ShareUrl' value={remoteUrlSource.sourceUrl} />
                            <input type='hidden' name='MediaUrl' id='MediaUrl' value={remoteUrlSource.sourceImageUrl} />

                            <input id='RemoteUrlSource_SourceAttribution' name='RemoteUrlSource.SourceAttribution' value={remoteUrlSource.sourceAttribution} type='hidden' />
                            <input id='RemoteUrlSource_SourceAttributionUrl' name='RemoteUrlSource.SourceAttributionUrl' value={remoteUrlSource.sourceAttributionUrl} type='hidden' />
                            <input id='RemoteUrlSource_SourceDescription' name='RemoteUrlSource.SourceDescription' value={remoteUrlSource.sourceDescription} type='hidden' />
                            <input id='RemoteUrlSource_SourceImageTitle' name='RemoteUrlSource.SourceImageTitle' value={remoteUrlSource.sourceImageTitle} type='hidden' />
                            <input id='RemoteUrlSource_SourceImageUrl' name='RemoteUrlSource.SourceImageUrl' value={remoteUrlSource.sourceImageUrl} type='hidden' />
                            <input id='RemoteUrlSource_SourceTitle' name='RemoteUrlSource.SourceTitle' value={remoteUrlSource.sourceTitle} type='hidden' />
                            <input id='RemoteUrlSource_SourceUrl' name='RemoteUrlSource.SourceUrl' value={remoteUrlSource.sourceUrl} type='hidden' />
                            {
                                hasLocalImageUrls ?
                                    <input id='RemoteUrlSource_LocalImageUrl' name='RemoteUrlSource.LocalImageUrl' value={remoteUrlSource.localImageUrls[0]} type='hidden' />
                                    : ''
                            }
                        </div>
                        <a href='#' className='close-remote-link-content'>×</a>
                    </div>
                );
            }
        }
    };

    /**
     * Builds the html to display the profile image
     * @param {object} iden identity profile
     * @returns {HTMLElement} html to display the image
     */
    const renderProviderImage = (iden) => {
        if (identityProvider.name === 'Reddit') {
            return <img src='/reddit_50_x_50.png' height='75' width='75' alt='identity provider' />;
        } else if (identityProvider.name === 'Pinterest') {
            return <img src='/Pinterest-badge-75px.png' height='75' width='75' alt='identity provider' />;
        } else {
            return <img src={iden.image} height='75' width='75' alt='identity provider' />;
        }
    };

    /**
     * Builds out html to display the files
     * @param {string} fileNames list of one or more urls
     * @param {string} remoteUrlSource url of remote page
     * @returns {HTMLElement|void} html to display the files or nothing
     */
    const renderPostedFiles = (fileNames, remoteUrlSource) => {
        if (fileNames) {
            const hasFiles = fileNames.IndexOf(',') > -1,
                images = fileNames.split(',');

            return (
                <div className='remote-image-content'>
                    {hasFiles ?
                        (() => {
                            for (var i = 0; i < images.length; i++) {
                                if (images[`${i}`]) {
                                    renderRemoteImage(images[`${i}`]);
                                }
                            }
                        })()
                        :
                        renderRemoteImage(images)
                    }
                </div>
            );
        } else if (remoteUrlSource !== null && remoteUrlSource !== 'undefined') {
            return renderRemoteSource(remoteUrlSource);
        }
    };

    /**
     * Display the share url
     * @param {string} shareUrl uri
     * @returns {HTMLElement|void} html or nothing
     */
    const renderShareUrl = (shareUrl) => {
        if (shareUrl) {
            return (
                <div className='social-post-share-url'>
                    <a href={shareUrl} target='_blank' rel='noopener noreferrer'>{shareUrl}</a>
                </div>
            );
        } else {
            return;
        }
    };

    /**
     * Builds the album html
     * @param {string} providerName name of provider
     * @param {string} albumId id of album
     * @param {string} socialGroupId id of social group
     * @param {string} identityAccountProviderIdentityId id of identity account provider
     * @returns {HTMLElement|void} html to display the album info or nothing
     */
    const renderPinterstAlbum = (providerName, albumId, socialGroupId, identityAccountProviderIdentityId) => {
        if (providerName === 'Pinterest') {
            return (
                <div className='board-item' id='AlbumId' data-social-group-id={socialGroupId} data-album-id={`identityAccountProviderIdentityId}|${albumId}`} data-identity-id={identityAccountProviderIdentityId}>
                    {albumId}
                </div>
            );
        } else {
            return;
        }
    };

    /**
     * Builds the html to display the button dropdown
     * @param {object} post object with content to display
     * @returns {ReactComponentElement} html
     */
    const renderDropdown = (post) => {
        return (
            <Col sm={3} md={3} className='smaller'>
                <Button id={post.postId} onClick={onClickReschedule} variant='success' size='sm' title='Reschedule this post to another date or time'>Reschedule</Button>
            </Col>
        );
    };

    /**
     * Convert the post's numeric status to its string representation
     * @param {number} status value representing the post's status
     * @returns {string} string representation of the post's status
     */
    const renderPostStatus = (status) => {
        let ps = status;

        Object.keys(config.postStatus).forEach(key => {
            if (config.postStatus[`${key}`] === status) {
                ps = pastTense(camelCase(key));
            }
        });

        return `${ps} on`;
    };

    return (
        <div
            ref={drag}

            key={post.postId}
            className={`calendar-item ${identityProvider.name.toLowerCase()} ui-sortable-handle ${isPast ? 'disabled' : ''}`}
        >
            {isMoving && <OverlayMove />}
            <Row>
                <Col>
                    {canEdit ?
                        <OverlayTrigger
                            trigger='click'
                            placement='bottom'
                            overlay={<Popover placement='bottom' className='mini-menu'>
                                <Popover.Body>
                                    <div><Link to={`/${post.applicationId}/social/${post.socialGroupId}/post/${post.postId}${query}`} onClick={scrollTop} className='edit-post'>Edit</Link></div>
                                    <div><Link to={`/${post.applicationId}/social/${post.socialGroupId}/post/master/${post.socialPostCode}${query}`} onClick={scrollTop} className='edit-post'>Edit Master</Link></div>
                                    <div><Link to={'#'} id={post.postId} data-identity-id={post.id} onClick={onClickCopy} className='copy-post'>Copy</Link></div>
                                    <div><Link to={'#'} id={post.postId} data-identity-id={post.id} onClick={onClickReschedule} className='copy-post'>Reschedule</Link></div>
                                    <div><Link to={'#'} id={post.postId} data-identity-id={post.id} onClick={onClickDelete} className='delete-post'>Delete</Link></div>
                                </Popover.Body>
                            </Popover>}
                        >
                            <FontAwesomeIcon icon={faEllipsisV} className='sub-menu' title='Options' />
                        </OverlayTrigger>
                        :
                        <FontAwesomeIcon icon={faEllipsisV} className='sub-menu disabled' title='Options' />
                    }
                </Col>
                <Col sm={6} md={6} className='text-right'>
                    {canEdit ?
                        <div className='move-anchor' title='Move'><FontAwesomeIcon icon={faArrowsAlt} /></div>
                        :
                        <div className='move-anchor disabled' title='Move'><FontAwesomeIcon icon={faArrowsAlt} /></div>
                    }
                </Col>
            </Row>
            <Row title={`${post.title} - ${formatDate(postDate, 'dd-MMM-yyyy HH:mm')}`}>
                <Col sm={12}>
                    <OverlayTrigger
                        trigger='click'
                        placement='bottom'
                        overlay={<Popover placement='bottom'>
                            <Popover.Body>
                                <div id={post.postId} data-dropdown-content className='f-dropdown content' aria-hidden='true' tabIndex='-1'>

                                    <div className='social-post'>
                                        <div className='social-post-background'>
                                            <Icon name={identityProvider.iconName} />
                                        </div>
                                        <Row className='social-post-main-content'>
                                            <Col lg={2} className='d-sm-none d-md-block'>
                                                <div>
                                                    <a className='th radius' href='#'>
                                                        {identity && renderProviderImage(identity)}
                                                    </a>
                                                </div>
                                            </Col>

                                            <Col sm={6} md={6}>
                                                <div className='social-post-content'>
                                                    <span>{post.message}</span>

                                                    {renderPostedFiles(post.fileNames, post.remoteUrlSource)}
                                                </div>
                                                {renderShareUrl(post.shareUrl)}
                                                {renderPinterstAlbum(identityProvider.name, post.albumId, post.socialGroupId)}
                                            </Col>

                                            <Col sm={2} md={2} className='text-smaller'>
                                                {renderPostStatus(post.postStatus)}
                                            </Col>

                                            <Col sm={2} md={2} className='text-smaller'>
                                                <div className='date-posted' title='date posted'>
                                                    <FontAwesomeIcon icon={faClock} /> <time>{post.postDate ? formatDate(post.postDate || post.ErrorDate || post.scheduledPostDate, 'dd-MMM-yyyy HH:mm') : ''}</time>
                                                </div>
                                            </Col>

                                            {showActions && renderDropdown(post)}

                                        </Row>
                                        <Row className='social-post-secondary-content'>
                                            <Col sm={6} md={1} className='text-smaller'>
                                                &nbsp;
                                            </Col>

                                            <Col sm={6} md={11} className='text-smaller'>
                                                <div className='date-scheduled' title='modified date'>
                                                    Scheduled for <FontAwesomeIcon icon={faClock} /> <time>{formatDate(post.scheduledPostDate, 'dd-MMM-yyyy HH:mm')}</time>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                            </Popover.Body>
                        </Popover>}
                    >
                        <div>
                            <Icon name={identityProvider.iconName} />&nbsp;
                            <span className='scheduled-time'>{formatDate(postDate, 'HH:mm')}</span> {post.title}
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    );
}
