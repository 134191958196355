import React from 'react';
import { useDrag } from 'react-dnd';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faExclamationTriangle, faKey } from '@fortawesome/free-solid-svg-icons';
import { LoaderButton } from '../../../components/LoaderButton';
import { Icon } from '../../components/Social/Icon';
import { formatDate, isAfterDate } from '../../../assets/scripts/dateLib';
import config from '../../../config';

/**
 * Identity card page
 * @param {object} argv object defining properties to use in this function
 * @param {object} argv.post post object
 * @param {object} argv.identity user's identity object
 * @param {Function} argv.onSetIdentity function to set the state of the identity object
 * @param {object} argv.provider provider object
 * @param {Function} argv.onClickAddIdentity function to execute when adding a new identity
 * @param {Function} argv.onClickRenewPageToken function to execute when renewing a Page's token
 * @param {boolean} argv.isRefreshing whether or not the profile is being refreshed
 * @param {Function} argv.onChange function to execute when the submit button is clicked
 * @param {Function} argv.onSelectProfile function to execute when a profile is selected in the UI
 * @param {boolean} argv.forceActive whether or not the application has a subscription and can be used/modified
 * @returns {HTMLElement} html for identity card block
 */
export function PostIdentityCard({
    post,
    identity,
    onSetIdentity,
    provider,
    onClickAddIdentity,
    onClickRenewPageToken,
    isRefreshing,
    onChange,
    onSelectProfile,
    forceActive
}) {

    const [{ isDragging }, drag] = useDrag({ // eslint-disable-line no-unused-vars
            type: 'Identity',
            item: { type: 'Identity', identityId: identity ? identity.id : 0, order: identity ? identity.order : 0 },
            collect: monitor => ({
                isDragging: !!monitor.isDragging()
            }),
            canDrag: true
        // end: (item, monitor) => {
        //     console.log(item);
        // }
        }),
        aspectRatio = post && post.media ? Number(post.media.height / post.media.width).toFixed(2) : 0,
        // eslint-disable-next-line no-extra-parens
        isWithinAspecRatio = (aspectRatio === 1.91 || aspectRatio === 1.25),
        isValidPinterestImageType = post && post.media ? ['image/jpeg', 'image/png', 'video/mp4'].indexOf(post.media.contentType) > -1 : true;

    /**
     * Shell function to pass current identity to main function
     * @param {Event} e mouse click event
     * @returns {void}
     */
    const onClickRenewPageTokenWithIdentity = (e) => {
        e.preventDefault();

        onSetIdentity && onSetIdentity(identity);
        onClickRenewPageToken(identity);
        return;
    }

    /**
     * Build out a notice if the identity profile's token has expired
     * @param {object} profile identity provider profile with details on token
     * @returns {HTMLElement} html building out the remote source UI
     */
    const renderTokenExpiredButton = (profile) => {
        if (profile.accessTokenExpirationUtc
            && isAfterDate(new Date(), profile.accessTokenExpirationUtc)
            && post.postStatus !== config.postStatus.POSTED) {

            if (['Page', 'Instagram'].indexOf(profile.identityType) > -1) {
                return (
                    <Row className='justify-content-md-center'>
                        <Col sm md={10}>
                            <LoaderButton
                                variant='warning'
                                id={profile.providerId}
                                onClick={onClickRenewPageTokenWithIdentity}
                                title='Renew Expired Page Token'
                                size='sm'
                                block='true'
                                isLoading={isRefreshing}
                            >
                                <FontAwesomeIcon icon={faKey} />&nbsp;Renew
                            </LoaderButton>
                            <small className='smaller'>Expired on: {formatDate(profile.accessTokenExpirationUtc, 'dd-MMM-yyyy HH:mm')}</small>
                        </Col>
                    </Row>
                );
            }else {
                return (
                    <Row className='justify-content-md-center'>
                        <Col sm md={10}>
                            <LoaderButton
                                variant='warning'
                                id={profile.providerId}
                                onClick={onClickAddIdentity}
                                title='Renew Expired Token'
                                size='sm'
                                block='true'
                                isLoading={isRefreshing}
                            >
                                <FontAwesomeIcon icon={faKey} />&nbsp;Refresh
                            </LoaderButton>
                            <small className='smaller'>Expired on: {formatDate(profile.accessTokenExpirationUtc, 'dd-MMM-yyyy HH:mm')}</small>
                        </Col>
                    </Row>
                );
            }
            // return (
            //     <Row className='justify-content-md-center'>
            //         <Col sm md={10}>
            //             <LoaderButton
            //                 variant='warning'
            //                 id={profile.providerId}
            //                 onClick={onClickAddIdentity}
            //                 title='Refresh Identity Token'
            //                 size='sm'
            //                 block='true'
            //                 isLoading={isRefreshing}
            //             >
            //                 <FontAwesomeIcon icon={faKey} />&nbsp;Refresh Expired Token
            //             </LoaderButton>
            //             <small className='smaller'>Expired on: {formatDate(profile.accessTokenExpirationUtc, 'dd-MMM-yyyy HH:mm')}</small>
            //         </Col>
            //     </Row>
            // );
        } else {
            return '';
        }
    };

    return (
        identity &&
        <div
            key={identity.id}
            id={identity.id}
            className={`social-identity-list-item ${post && post.identitys && post.identitys.indexOf(identity.id) > -1 || forceActive === true ? 'active' : 'inactive'}`}
            onClick={onSelectProfile}
            ref={drag}
        >
            {identity.identityType === 'Page' ?
                <div className='page-identity-background'>
                    <FontAwesomeIcon icon={faBuilding} />&nbsp;
                </div>
                :
                ''
            }
            <div className={`card-label ${provider.name.toLowerCase()} ${post && post.identitys && post.identitys.indexOf(identity.id) > -1 || forceActive === true ? 'active' : 'inactive'}`} title='Click here to activate this Provider'>
                <div className='label-text'>
                    <Icon name={provider.iconName} />
                </div>
            </div>
            <div className='card-content'>
                <Row>
                    <Col md={3} className='social-identity-photo'>
                        <img src={identity.image} height='50' width='50' alt='social identity' />
                    </Col>
                    <Col md={9}>
                        <div className='social-identity-list-item-name'>{identity.preferredUsername || identity.displayName}</div>
                    </Col>
                </Row>
                {identity.providerId === 27 ?
                    <Row>
                        <Col>
                            {!identity.albumList || identity.albumList.length === 0 ?
                                <div>
                                    <p className='smaller'><FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;Before posting to Pinterest you must get your list
                                        of boards. Go to <Link to={`/${post.applicationId}/social/${post.socialGroupId}`}>Settings</Link> and refresh the list, then
                                        come back here to select the board to post to.
                                    </p>
                                </div>
                                :
                                <Form.Group controlId='albumId'>
                                    <Form.Label>Pinterest Board</Form.Label>
                                    <Form.Control
                                        as='select'
                                        onChange={onChange}
                                        value={post.albumId}
                                        disabled={post && post.identitys && post.identitys.indexOf(identity.id) > -1 || forceActive === true ? false : true}
                                    >
                                        <option key='0' />
                                        {
                                            // eslint-disable-next-line
                                            identity.albumList.map(b => {
                                                return (
                                                    <option key={b.id} value={b.id}>{b.name}</option>
                                                );
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                            }
                        </Col>
                    </Row>
                    :
                    ''
                }
                {identity.providerId === 27 && !isValidPinterestImageType ?
                    <Row>
                        <Col>
                            <Alert variant='danger'>
                                Pinterest only allows JPG or PNG file types
                            </Alert>
                        </Col>
                    </Row>
                    :
                    ''
                }
                {identity.providerId === 15 && !isWithinAspecRatio ?
                    <Row>
                        <Col>
                            <Alert variant='danger'>
                                Image aspect ratio is not supported
                            </Alert>
                        </Col>
                    </Row>
                    :
                    ''
                }
                {identity.providerId === 15 ?
                    <Row>
                        <Col style={{ fontSize: '0.6rem' }}>
                            ** Instagram requires an aspect ratio 1.25 OR 1.91
                        </Col>
                    </Row>
                    :
                    ''
                }
                <Row className='social-identity-list-item-metrics'>
                    <Col sm={6} className='social-identity-details'>
                        Followers: {identity.followers}
                    </Col>
                    <Col sm={6} className='social-identity-details'>
                        Following: {identity.following}
                    </Col>
                </Row>
                {renderTokenExpiredButton(identity)}
            </div>
        </div>
    );
}
