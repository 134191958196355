import { API, Auth } from 'aws-amplify';

/**
 * Get the current customer's stripe billing info
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise with stripe billing info
 */
export const getCustomerBillingInfo = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/billing/info`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Get the current customer's stripe subscription info
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise with stripe subscription info
 */
export const getSubscriptionInfo = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/subscription`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Pull the list of available countries from Stripe
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise with stripe country abbreviation list
 */
export const getCountryList = async (token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', '/app/billing/countries', {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Creates a new customer with Stripe and gets the customer details
 * @param {object} customer customer details used to create the new customer at Stripe
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise which resolves to the new stripe customer object
 */
export const saveCustomerDetails = async (customer, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.post('stitchz-web-api', '/app/billing', {
            headers: {
                Authorization: token
            },
            body: {
                userId: customer.username,
                name: customer.name,
                email: customer.email,
                address: customer.address,
                city: customer.city,
                state: customer.state,
                postal: customer.postal,
                country: customer.country,
                subscriptionCustomerId: customer.subscriptionCustomerId
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Save and Create a new payment method for this application
 * @param {string} appId application id
 * @param {object} intent payment method info
 * @param {object} customer AWS amplify user token
 * @param {object} billingCustomer customer to bill to
 * @param {string} token token verifier
 * @returns {Promise} promise resolving the updated payment info
 */
export const saveNewPaymentMethodCustomer = async (appId, intent, customer, billingCustomer, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/billing`, {
            headers: {
                Authorization: token
            },
            body: {
                paymentMethodId: intent.paymentId,
                subscriptionType: intent.subscriptionType,
                subscriptionCustomerId: customer.id,
                email: customer.email,
                name: billingCustomer.name,
                address: billingCustomer.address_line1,
                city: billingCustomer.address_city,
                state: billingCustomer.address_state,
                postal_code: billingCustomer.address_postal,
                country: billingCustomer.address_country
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Setup a new credit card in preparation for subscribing to the service
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise resolving the payment intent at Stripe
 */
export const setupNewPaymentIntent = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.get('stitchz-web-api', `/app/${appId}/billing/intent`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Saves the updated payment info
 * @param {string} appId application id
 * @param {object} billingDetails collection of billing details that stripe accepts
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise response with updated info
 */
export const savePaymentMethod = async (appId, billingDetails, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/billing/info`, {
            headers: {
                Authorization: token
            },
            body: billingDetails
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Sends the delete request
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise response with deleted info
 */
export const deletePaymentMethod = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/billing/info`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Save the new subscription to the database
 * @param {string} appId application id
 * @param {number} subscriptionType subscription type id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} promise to resolve the save response
 */
export const saveSubscription = async (appId, subscriptionType, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.put('stitchz-web-api', `/app/${appId}/subscription`, {
            headers: {
                Authorization: token
            },
            body: {
                subscriptionType: subscriptionType
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Cancels the subscription plan for this application, saving the changes to the database
 * @param {string} appId application id
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const cancelSubscription = async (appId, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        return API.del('stitchz-web-api', `/app/${appId}/subscription`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
