import { API, Auth } from 'aws-amplify';

/**
 * Retrieve a list of user objects from Cognito
 * @param {string} filter username filter
 * @param {string} [pageToken] pagination token
 * @returns {Promise} promise containing an array of user objects from Cognito
 */
export const getUsersList = async (filter, pageToken) => {
    try {
        const user = await Auth.currentAuthenticatedUser(),
            token = user.signInUserSession.accessToken.jwtToken;

        let url = '/users';

        if (filter) {
            url = url + '?filter=name&value=' + filter;

            if (pageToken) {
                url = url + '&pageKey=' + pageToken;
            }
        } else {
            if (pageToken) {
                url = url + '?pageKey=' + pageToken;
            }
        }

        return API.get('stitchz-web-api', url, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a user object from Cognito
 * @param {string} username unique user id of the user object to return
 * @returns {Promise} promise containing the requested user object
 */
export const getUser = async (username) => {
    try {
        const user = await Auth.currentAuthenticatedUser(),
            token = user.signInUserSession.accessToken.jwtToken;

        return API.get('stitchz-web-api', `/user/${username}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Enables or Disables a user object in Cognito
 * @param {string} username unique user id of the user object to return
 * @param {boolean} enableOrDisable true to enable or false to disable
 * @returns {Promise} promise containing the requested user object
 */
export const enableDisableUser = async (username, enableOrDisable) => {
    try {
        const user = await Auth.currentAuthenticatedUser(),
            token = user.signInUserSession.accessToken.jwtToken;

        let url = `/user/${username}/${enableOrDisable ? 'enable' : 'disable'}`;

        return API.put('stitchz-web-api', url, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Confirms a user object in Cognito
 * @param {string} username unique user id of the user object to return
 * @returns {Promise} promise containing the requested user object
 */
export const confirmUser = async (username) => {
    try {
        const user = await Auth.currentAuthenticatedUser(),
            token = user.signInUserSession.accessToken.jwtToken;

        return API.put('stitchz-web-api', `/user/${username}/confirm`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Administrative delete of a user object from Cognito
 * @param {string} username unique user id of the user object to return
 * @returns {Promise} promise containing the requested user object
 */
export const removeUser = async (username) => {
    try {
        const user = await Auth.currentAuthenticatedUser(),
            token = user.signInUserSession.accessToken.jwtToken;

        return API.del('stitchz-web-api', `/user/${username}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Deletes a user object from Cognito
 * @returns {Promise} promise containing the requested user object
 */
export const getMyAccount = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser(),
            token = user.signInUserSession.accessToken.jwtToken;

        return API.get('stitchz-web-api', `/me/${user.username}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Deletes a user object from Cognito
 * @returns {Promise} promise containing the requested user object
 */
export const removeMyAccount = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser(),
            token = user.signInUserSession.accessToken.jwtToken;

        return API.del('stitchz-web-api', `/me/${user.username}`, {
            headers: {
                Authorization: token
            }
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
