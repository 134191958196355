import React, { useState, useEffect, ReactComponentElement } from 'react';
import { ListGroup, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { getApplications } from '../libs/Api/application';
import { Loading } from '../../components/Loading';
import { useAppContext } from '../../assets/scripts/contextLib';
import { useRouter } from '../../assets/scripts/hooksLib';
import { adjustHeader } from '../../assets/scripts/utilsLib';
import { onError } from '../../assets/scripts/errorLib';
import config from '../../config';
// import '../../assets/styles/app.scss';

/**
 * Home page
 * @returns {HTMLElement} html for home page block
 */
export default function Home() {
    const router = useRouter();
    const [apps, setApps] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    // load the list of applications
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            if (!isAuthenticated) {
                return;
            }

            try {
                adjustHeader();
                // const apps = await getApplications();
                // setApps(apps);
                const { status, message, body } = await getApplications()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setApps(body.Items);
                }
            } catch (e) {
                console.error(e);
                if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.push(`/login?redirect=${router.pathname}${router.search}`);
                    router.history(`../login.html?redirect=${router.pathname}${router.search}`);
                } else {
                    onError(e);
                }
            }

            setIsLoading(false);
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [isAuthenticated, router]);

    /**
     * Get the subscription type's properties
     * @param {number} subscriptionType subscription type id to get properties for
     * @returns {object} subscription type properties
     */
    const getSubscriptionType = subscriptionType => {
        if (subscriptionType > 0 && subscriptionType < 6) {
            return config.subscriptionTypes.find(s => s.id === subscriptionType);
        } else {
            return {
                maxSocialGroupMemberLimit: 0,
                maxProviderLimit: 0,
                socialGroupLimit: 0
            }
        }
    };

    /**
     * Builds and displays the UI for a user specific list of applications
     * @param {Array} apps collection of applications
     * @returns {ReactComponentElement} Html list of application details
     */
    const renderAppsList = (apps) => {
        // return [{}].concat(apps && apps.body ? apps.body.Items : []).map((app, i) =>
        return apps.map((app) =>
            <LinkContainer key={app.applicationId} to={`${app.applicationId}`}>
                <ListGroup.Item action>
                    <h4>{app.name}</h4>
                    <div>Members: {app.applicationMembers.length}/{app && getSubscriptionType(app.subscriptionType).maxSocialGroupMemberLimit}</div>
                    <div>App Providers: {app.applicationProviders ? app.applicationProviders.length : 0}/{app && getSubscriptionType(app.subscriptionType).maxProviderLimit}</div>
                    <div>Social Groups: {app.socialGroups ? app.socialGroups.length : 0}/{app && getSubscriptionType(app.subscriptionType).socialGroupLimit}</div>
                    {'Created: ' + new Date(app.createdAt).toLocaleString()}
                </ListGroup.Item>
            </LinkContainer>
        );
    };

    /**
     * Builds the UI for the app list header
     * @returns {ReactComponentElement} html
     */
    const renderLander = () => {
        return (
            <LinkContainer key='new' to='/new'>
                <ListGroup.Item action>
                    <h4>
                        <b>{'\uFF0B'}</b> Create a new app
                    </h4>
                </ListGroup.Item>
            </LinkContainer>
        );
    };

    /**
     * Builds the UI for the list of apps to display
     * @returns {ReactComponentElement} html
     */
    const renderApps = () => {
        return (
            <div className='apps'>
                { isLoading ? <Loading pageLoading={isLoading} /> :
                    <Container>
                        <div>
                            <h3>Application List</h3>
                        </div>
                        <ListGroup>
                            { renderLander() }
                            {renderAppsList(apps)}
                        </ListGroup>
                    </Container>
                }
            </div>
        );
    };

    return (
        <div className='Home'>
            {isAuthenticated ? renderApps() : renderLander()}
        </div>
    );
}
