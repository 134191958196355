import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import MyRoutes from './MyRoutes';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faHome, faUserFriends, faBurn, faDollarSign, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from '../assets/scripts/hooksLib';
import { AppContext } from '../assets/scripts/contextLib';
import { getPostsByStatus } from '../app/libs/Api/posts';
import config from '../config';
import InternalFooter from '../components/InternalFooter';
import { onError } from '../assets/scripts/errorLib';
import '../assets/styles/me.scss';

/**
 * Main Stitchz.net entry
 * @returns {HTMLElement} html for the main entry
 */
function Me() {
    const router = useRouter('/me');
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasAlerts, setHasAlerts] = useState(false);

    // load user authentication state
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const user = await Auth.currentSession();

                userHasAuthenticated(true);
                setUser(user);

                if (user.accessToken && user.accessToken.payload && user.accessToken.payload['cognito:groups']) {
                    setIsAdmin(user.accessToken.payload['cognito:groups'].indexOf('Administrators') > -1);
                }
            } catch (e) {
                if (e !== 'No current user') {
                    onError(e);
                }
            }

            setIsAuthenticating(false);
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [setUser, userHasAuthenticated]);

    // load ERRORED posts
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            if (router.query && router.query.id && router.query.grpId) {
                try {
                    const { status, message, body } = await getPostsByStatus(router.query.id, router.query.grpId, config.postStatus.ERRORED, 'scheduledPostDate', 'scheduledPostDate')
                        .catch(err => {
                            return {
                                status: 'failed',
                                message: err.response && err.response.data ? err.response.data.message : err.message
                            };
                        });

                    if (status !== 'OK') {
                        onError(message);
                    } else {
                        setHasAlerts(body && body.Items.length > 0);
                    }
                } catch (e) {
                    // console.error(e);
                } finally {
                    // setPageLoading(false);
                }
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    /**
     * Get's the current location and add it to the redirect url
     * @returns {string} redirect url
     */
    const getCurrentLocation = () => {
        const path = window.location.pathname;

        if (path !== '/' && path !== '/login.html' && path !== '/signup.html') {
            return <a href={`../login.html?redirect=${encodeURIComponent(path)}`} rel='nofollow'>
                <Nav.Link>Login</Nav.Link>
            </a>;
        }

        return <a href='/login.html' rel='nofollow'>
            <Nav.Link>Login</Nav.Link>
        </a>;
    }

    /**
     * Log off website
     * @returns {void}
     */
    // const handleLogout = async () => {
    //     await Auth.signOut();

    //     userHasAuthenticated(false);

    //     router.history('/');
    //     scrollTop();
    // };

    return (
        !isAuthenticating &&
        <>
            <Container className='App' fluid>
                <Navbar collapseOnSelect>
                    <Navbar.Brand>
                        <span className='stitchz-fonts-stitchz stitchz-6x' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto'>
                            <a href='/' className='nav-link'><FontAwesomeIcon icon={faHome} /> Home</a>
                            <a href='/features.html' className='nav-link'><FontAwesomeIcon icon={faBurn} /> Features</a>
                            <a href='/pricing.html' className='nav-link'><FontAwesomeIcon icon={faDollarSign} /> Pricing</a>
                            {isAuthenticated
                                ?
                                <Nav.Link href='/app' rel='nofollow'><FontAwesomeIcon icon={faUserFriends} /> Apps</Nav.Link>
                                :
                                <></>
                            }
                            {isAdmin ?
                                <Nav.Link href='/admin' rel='nofollow'><FontAwesomeIcon icon={faUsersCog} /> Admin</Nav.Link>
                                :
                                <></>
                            }
                        </Nav>
                        <Nav className='justify-content-end'>
                            {isAuthenticated
                                ?
                                <>
                                    {user ?
                                        <div className="nav-item">
                                            <a href="/me" data-rr-ui-event-key="/me" className="nav-link" rel='nofollow'>{user.idToken.payload.name}</a>
                                        </div>
                                        :
                                        ''
                                    }
                                    <Navbar.Text title='Logout'>
                                        <a href="/logout.html" rel='nofollow'>
                                            <FontAwesomeIcon icon={faPowerOff} />
                                        </a>
                                    </Navbar.Text>
                                </>
                                : <>
                                    <Navbar.Text>
                                        <a href='/signup.html' rel='nofollow'>
                                            <Nav.Link>Signup</Nav.Link>
                                        </a>
                                    </Navbar.Text>
                                    <Navbar.Text>
                                        { getCurrentLocation() }
                                    </Navbar.Text>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
                    <MyRoutes hasAlerts={hasAlerts} />
                </AppContext.Provider>
            </Container>
            <InternalFooter />
        </>
    );
}

export default Me;
