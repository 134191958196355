import React from 'react';
import config from '../../../../config';
import { OverlayBackdrop } from './OverlayBackdrop';
import { useDrop } from 'react-dnd';

/**
 * Builds the calendar items for the current week day of the month
 * @param {object} argv object defining properties to use in this function
 * @param {number} argv.week week this day is associated with
 * @param {number} argv.day day of the week
 * @param {boolean} argv.isCurrentMonth whether or not this day is in the current month in scope (for styling purposes)
 * @param {HTMLElement} argv.postLink html link pointing to new post url
 * @param {Function} argv.onMoveReschedulePost function triggered when the post is moved in a calendar
 * @param {boolean} argv.isCurrentDay whether or not this day is in the current day in scope (for styling purposes)
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {object} argv.children react child object
 * @returns {HTMLElement} html
 */
export function WeekDay({
    week,
    day,
    isCurrentMonth,
    postLink,
    onMoveReschedulePost,
    isCurrentDay,
    canEdit,
    children
}) {

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [config.itemTypes.POST, config.itemTypes.DRAFT],
        drop: (item) => onMoveReschedulePost(week, day, item),
        canDrop: () => { return isCurrentMonth && canEdit },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
            didDrop: !!monitor.didDrop()
        })
    });

    return (
        <td
            ref={drop}
            className={`${isCurrentMonth ? 'currentMonth ' : 'disabled '}${isCurrentDay ? 'isToday' : ''}`}
        >
            <div>
                {postLink}
            </div>
            {children}
            {isOver && !canDrop && <OverlayBackdrop color='red' />}
            {isOver && canDrop && <OverlayBackdrop color='green' />}
        </td>
    );
}
