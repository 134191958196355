import React, { ReactComponentElement } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../../components/Loading';
import { LoaderButton } from '../../../../components/LoaderButton';
import { PostsByMonth } from '../../../components/Social/Calendar/PostsByMonth';
import { formatDate } from '../../../../assets/scripts/dateLib';
import { Calendar } from '../../../../assets/scripts/calendarLib';
import '../../../../assets/styles/calendar.scss';

/**
 * Builds the current date's calendar items
 * @param {object} argv object defining properties to use in this function
 * @param {Array} argv.calendarPosts collection of posts for this week
 * @param {object} argv.socialGroup social group associated with these posts
 * @param {boolean} argv.pageLoading whether or not the master page is loading
 * @param {boolean} argv.isLoading whether or not the function is running
 * @param {object} argv.calendarDate current date in scope
 * @param {Function} argv.setCalendarDate function to update the calendarDate
 * @param {Function} argv.onChangeEdit function to track/update the state upon text field changes
 * @param {Function} argv.onClickCreatePost function to trigger a post creation
 * @param {Function} argv.onClickDeletePost function to control the deletion of the post
 * @param {Function} argv.onClickCopy function to control the copying of the post
 * @param {Function} argv.onReschedulePost function to control rescheduling the post
 * @param {boolean} argv.isAllowedToPost whether or not this application is allowed to post a share
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {object} argv.router routing object history
 * @param {Function} argv.onMoveReschedulePost triggered when the post is moved in a calendar
 * @param {boolean} argv.isMoving whether or not the post is scheduling days
 * @returns {HTMLElement} html
 */
export function CalendarIndex({
    calendarPosts,
    socialGroup,
    pageLoading,
    isLoading,
    calendarDate, setCalendarDate,
    onChangeEdit,
    onClickCreatePost,
    onClickDeletePost,
    onClickCopy,
    onReschedulePost,
    isAllowedToPost,
    canEdit,
    router,
    onMoveReschedulePost,
    isMoving
}) {

    /**
     * Resets the navigation to the current date
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const goToToday = (e) => {
        e.preventDefault();

        router.history(`/${router.query.id}/social/${socialGroup.id}/calendar`);
    };

    /**
     * Navigates the UI to the previous month's calendar
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onMovePrev = (e) => {
        e.preventDefault();

        setCalendarDate(new Calendar(calendarDate.previousMonth.getFullYear(), calendarDate.previousMonth.getMonth(), 1));

        router.history(`/${router.query.id}/social/${socialGroup.id}/calendar${getLastMonthUrl()}`);
    }

    /**
     * Navigates the UI to the next month's calendar
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onMoveNext = (e) => {
        e.preventDefault();

        setCalendarDate(new Calendar(calendarDate.nextMonth.getFullYear(), calendarDate.nextMonth.getMonth(), 1));

        router.history(`/${router.query.id}/social/${socialGroup.id}/calendar${getNextMonthUrl()}`);
    }

    /**
     * Build the link to the previous month's calendar
     * @returns {string} link to previous month
     */
    const getLastMonthUrl = () => {
        return `?month=${calendarDate.previousMonth.getMonth() + 1}&year=${calendarDate.previousMonth.getFullYear()}#month`;
    };

    /**
     * Build the link to the next month's calendar
     * @returns {string} link to next month
     */
    const getNextMonthUrl = () => {
        return `?month=${calendarDate.nextMonth.getMonth() + 1}&year=${calendarDate.nextMonth.getFullYear()}#month`;
    };

    /**
     * Builds and displays html button if the user has permissions to edit
     * @returns {ReactComponentElement|void} html button element or nothing
     */
    const allowedToPost = () => {
        if (isAllowedToPost) {
            return (
                <LoaderButton
                    variant='primary'
                    isLoading={isLoading}
                    onClick={onClickCreatePost}>
                    Create Post
                </LoaderButton>
            );
        }
    };

    return (
        pageLoading ?
            <Loading pageLoading={pageLoading} /> :
            <Container>
                <Row>
                    <Col sm={12} md={9}>
                        <div className='page-header'>
                            <h1><FontAwesomeIcon icon={faCalendarAlt} />&nbsp;Calendar</h1>
                        </div>
                    </Col>
                    <Col md='auto'>
                        <Button
                            onClick={goToToday}
                        >
                            Today
                        </Button>
                    </Col>
                    <Col sm={12} md='auto'>
                        {allowedToPost()}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <td className='center'><Link to={getLastMonthUrl()} onClick={onMovePrev}>&lt;</Link></td>
                                    <td colSpan='5' className='center'>
                                        <h3>{`${formatDate(calendarDate.targetDate, 'MMMM')} ${formatDate(calendarDate.targetDate, 'yyyy')}`}</h3>
                                    </td>
                                    <td className='center'><Link to={getNextMonthUrl()} onClick={onMoveNext}>&gt;</Link></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sun</td>
                                    <td>Mon</td>
                                    <td>Tues</td>
                                    <td>Wed</td>
                                    <td>Thur</td>
                                    <td>Fri</td>
                                    <td>Sat</td>
                                </tr>

                                <PostsByMonth
                                    weeksInMonth={calendarPosts}
                                    appId={router.query.id}
                                    grpId={socialGroup.id}
                                    socialGroupIdentitys={socialGroup.socialGroupIdentitys}
                                    calendar={calendarDate}
                                    referer={encodeURIComponent(`${router.pathname}${router.location.search}${router.location.hash}`)}
                                    canEdit={canEdit}
                                    onChangeEdit={onChangeEdit}
                                    onClickDelete={onClickDeletePost}
                                    onClickReschedule={onReschedulePost}
                                    onClickCopy={onClickCopy}
                                    onClickCreatePost={onClickCreatePost}
                                    onMoveReschedulePost={onMoveReschedulePost}
                                    isMoving={isMoving}
                                />

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
    );
}
