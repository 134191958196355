import React from 'react';
import { Link } from 'react-router-dom';
import { PostsByDay } from './PostsByDay';
import { WeekDay } from './WeekDay';
import { v4 as uuidv4 } from 'uuid';
import { add, month, getDayOfMonth, getUnix, clone, today as isToday } from '../../../../assets/scripts/dateLib';

/**
 * Builds the current date's calendar items
 * @param {object} argv object defining properties to use in this function
 * @param {Array} argv.weeksDays collection of posts for the current week
 * @param {string} argv.appId unique application id for building out urls
 * @param {string} argv.grpId the application's social group id for building out urls
 * @param {Array} argv.socialGroupIdentitys collection of social identities to use
 * @param {Date} argv.targetDate date object for the month we're targeting
 * @param {Date} argv.currentDate date object for the date we're iterating over
 * @param {string} argv.referer encoded uri that referred to this page
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {Function} argv.onClickEdit ...
 * @param {Function} argv.onChangeEdit function to track/update the state upon text field changes
 * @param {Function} argv.onClickDelete function to control the deletion of the post
 * @param {Function} argv.onClickReschedule function to control rescheduling the post
 * @param {Function} argv.onClickCopy function to control the copying of the post
 * @param {Function} argv.onClickCreatePost function to trigger a post creation
 * @param {Function} argv.onMoveReschedulePost triggered when the post is moved in a calendar
 * @param {boolean} argv.isMoving whether or not the post is scheduling days
 * @returns {HTMLElement} html
 */
export function PostsByWeek({
    weeksDays,
    appId,
    grpId,
    socialGroupIdentitys,
    targetDate,
    currentDate,
    referer,
    canEdit,
    onClickEdit,
    onChangeEdit,
    onClickDelete,
    onClickReschedule,
    onClickCopy,
    onClickCreatePost,
    onMoveReschedulePost,
    isMoving
}) {

    let days = [],
        postsDate = clone(currentDate),
        i = 0;

    /**
     * Increase the date by 1 day
     * @param {Date} d date object to add days to
     * @returns {Date} updated date object
     */
    const incrDate = (d) => {
        let n = add(d, i, 'days');
        i++;

        return n;
    };

    if (weeksDays) {
        // loop over the days in the week
        for (var d = 0; d < weeksDays.length; d++) {
            const isCurrentMonth = month(currentDate) === month(targetDate),
                postLink = canEdit ? <Link onClick={onClickCreatePost} data-date={getUnix(currentDate)} className='calendar-day' to={{ pathname: `/${appId}/social/${grpId}/post`, search: `date=${getUnix(currentDate)}` }}>{getDayOfMonth(currentDate)}</Link>
                    : getDayOfMonth(currentDate);
            // console.log(weeksDays[d].posts);
            days.push(
                <WeekDay
                    key={`${weeksDays[`${d}`].id}-${d}`}
                    week={weeksDays[`${d}`].week}
                    day={d}
                    onMoveReschedulePost={onMoveReschedulePost}
                    isCurrentMonth={isCurrentMonth}
                    postLink={postLink}
                    isCurrentDay={isToday(incrDate(postsDate))}
                    canEdit={canEdit}
                >
                    <PostsByDay key={uuidv4()}
                        todaysPosts={weeksDays[`${d}`].posts}
                        socialGroupIdentitys={socialGroupIdentitys}
                        referer={referer}
                        canEdit={canEdit}
                        onClickEdit={onClickEdit}
                        onChangeEdit={onChangeEdit}
                        onClickDelete={onClickDelete}
                        onClickReschedule={onClickReschedule}
                        onClickCopy={onClickCopy}
                        isMoving={isMoving}
                    />
                </WeekDay>
            );

            // increment the calendar day header value
            currentDate = add(currentDate, 1, 'days');
        }
    }

    return days;
}
