import { API, Auth } from 'aws-amplify';

/**
 * Sends a txt message to chatgpt and waits for response
 * @param {string} message text message to send to chat gpt
 * @param {string} [token] AWS amplify user token
 * @returns {Promise} http message response promise
 */
export const sendChatGPTMessage = async (message, token) => {
    try {
        if (!token) {
            const user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.accessToken.jwtToken;
        }

        // setup the base request body
        let body = {
            message: message
        };

        return API.post('stitchz-web-api', '/chatgpt/message', {
            headers: {
                Authorization: token
            },
            body: body
        });
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
