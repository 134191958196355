import React, { useState, useEffect, ReactComponentElement } from 'react';
import { Container, Row, Col, Button, Card, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getApplicationDetails } from '../../libs/Api/application';
import { getPostsByStatus, deletePost, rePost } from '../../libs/Api/posts';
import { initiateNewIdentityRequest } from '../../libs/Api/socialgroup';
import { Loading } from '../../../components/Loading';
import { Icon } from '../../components/Social/Icon';
import { useRouter } from '../../../assets/scripts/hooksLib';
import { getLocalDateTime, formatDate } from '../../../assets/scripts/dateLib';
import { adjustHeader, generateRandomString, getErrorMessageTitle, getErrorMessageBody } from '../../../assets/scripts/utilsLib';
import { onError } from '../../../assets/scripts/errorLib';
import config from '../../../config';
import '../../../assets/styles/social.scss';

/**
 * Builds and displays an html page for all posts needing attention
 * @returns {HTMLElement} html
 */
export function Attention() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [posts, setPosts] = useState(null);
    const [socialGroup, setSocialGroup] = useState(null);

    // load app info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    if (body) {
                        setSocialGroup(body.socialGroups.find(sg => {
                            return sg.id === router.query.grpId;
                        }));

                        // make sure we have some groups and identities to work with
                        // const identities = body.socialGroups.reduce((total, current) => {
                        //     return total + (current.socialGroupIdentitys ? current.socialGroupIdentitys.length : 0);
                        // }, 0);
                        // setCanEdit(body.Enabled && identities > 0);
                    }
                }
            } catch (e) {
                console.error(e);
                if (e.response && e.response.status === 404) {
                    //console.log(e.response);
                    // router.push('/apps');
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.push(`/login?redirect=${router.pathname}${router.query}`);
                    router.history(`../login.html?redirect=${router.pathname}${router.query}`);
                } else {
                    onError(e);
                }
            } finally {
                // setPageLoading(false);
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router]);

    // load ERRORED posts
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const { status, message, body } = await getPostsByStatus(router.query.id, router.query.grpId, config.postStatus.ERRORED, 'scheduledPostDate', 'scheduledPostDate')
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    if (body) {
                        setPosts(body.Items);
                    }
                }
            } catch (e) {
                // console.error(e);
                if (e.response && e.response.status === 404) {
                    //console.log(e.response);
                    // router.push('/apps');
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.push(`/login?redirect=${router.pathname}${router.query}`)'
                    router.history(`../login.html?redirect=${router.pathname}${router.query}`);
                } else {
                    onError(e);
                }
            } finally {
                setPageLoading(false);
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router]);

    /**
     * Delete's the post from the database
     * @param {MouseEvent} e button click event
     * @returns {void} refreshes the state
     */
    const handleDelete = async (e) => {
        e.preventDefault();

        const confirmed = window.confirm('Do you want to delete this post from Stitchz?\r\rThis action is irreversible.\r\rClick OK to continue or Cancel to abort this action.');

        if (!confirmed) {
            return;
        }

        const postId = e.target.id;

        try {
            const { status, message } = await deletePost(router.query.id, router.query.grpId, postId)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // clean up the deleted post
                let prevState = [...posts],
                    nextState = [...posts],
                    index = prevState.findIndex(cbi => {
                        return cbi.postId === postId;
                    });
                nextState.splice(index, 1);

                setPosts(nextState);
            }
        } catch (err) {
            onError(err);
        } finally {
            //
        }
    };

    /**
     * Returns the identity object associated with the post
     * @param {string} id unique id for the identity account
     * @returns {object} identity object
     */
    const getIdentity = (id) => {
        return socialGroup && socialGroup.socialGroupIdentitys && socialGroup.socialGroupIdentitys.find(s => {
            return s.id === id;
        });
    };

    /**
     * onClick mouse event to start the Identity request process
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onClickAddIdentity = async (e) => {
        e.preventDefault();

        const target = e.target,
            // prov = app.applicationProviders.find(p => {
            //     return p.providerId === Number(target.id);
            // }),
            prov = config.providers.find(p => {
                return p.id === Number(target.id);
            }),
            reqBody = {
                state: await generateRandomString(8, 'hex')
                    .catch(err => {
                        console.error(err.message);
                        return null;
                    }),
                oauthScope: prov.canManageScope ? prov ? prov.scope : 'email' : '',
                providerId: target.id,
                requestorUrl: `${config.BASEURL}app/${router.query.id}/social/${socialGroup.id}`
            };

        try {
            const { status, message, body } = await initiateNewIdentityRequest(router.query.id, socialGroup.id, reqBody)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // redirect to IdP authentication page
                window.location = body.redirectUrl;
            }
        } catch (err) {
            onError(err);
        } finally {
            //
        }
    };

    /**
     * Sends a post to the service provider again
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onClickRepost = async (e) => {
        e.preventDefault();

        const confirmed = window.confirm('Do you want to Re-Post this post?\rRe-Posting will send the post to the respective provider again.');

        if (!confirmed) {
            return;
        }

        const postId = e.target.id;

        try {
            const { status, message } = await rePost(router.query.id, socialGroup.id, postId)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // clean up the deleted post
                let prevState = [...posts],
                    nextState = [...posts],
                    index = prevState.findIndex(cbi => {
                        return cbi.postId === postId;
                    });
                nextState.splice(index, 1);

                setPosts(nextState);
            }
        } catch (err) {
            onError(err);
        } finally {
            //
        }
    };

    /**
     * Build and display the post Error info section
     * @param {object} post post object
     * @returns {ReactComponentElement} html error section
     */
    const renderErrorInfo = (post) => {
        if (post && post.postStatus === config.postStatus.ERRORED) {
            let title = getErrorMessageTitle(post.Error, post.ErrorMessage),
                textMsg = getErrorMessageBody(post.ErrorMessage);

            return (
                <Card
                    bg='danger'
                    text='white'
                    className='mb-2'
                >
                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>
                            {textMsg}
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        } else {
            return '';
        }
    };

    /**
     * Build and displays the posts lists UI
     * @param {Array} list collection of posts
     * @returns {ReactComponentElement} html list of posts
     */
    const renderPostList = (list) => {
        const totalPosts = list ? list.length : 0;

        return (
            <Row>
                <Col>
                    <div className='border-border'>
                        <div className='box-header'>
                            <Row>
                                <Col>
                                    {totalPosts} Posts
                                </Col>
                            </Row>
                        </div>
                        <div className='box-content'>
                            <Row className='social-posts-list'>
                                <Col>
                                    {(!list || list.length === 0) &&
                                        <Row>
                                            <Col className='center'>
                                                <div>No posts needing your attention... nice job!</div>
                                            </Col>
                                        </Row>
                                    }
                                    {list && list.map((m, idx) => {  // eslint-disable-line no-unused-vars
                                        if (m.postStatus === config.postStatus.ERRORED) {
                                            const iden = getIdentity(m.identityId),
                                                provider = config.providers.find(p => {
                                                    return p.id === m.providerId;
                                                });

                                            return (
                                                <Row key={`${m.postId}-${m.socialPostCode}`} className='social-post'>
                                                    <Col>
                                                        <Row>
                                                            <Col md={1} className='d-sm-none d-md-block'>
                                                                <Row>
                                                                    <Col>
                                                                        <img src={!iden || !iden.image ? 'https://images.stitchz.net/images/user.png' : iden.image} height='75' width='75' alt='social identity' />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <div>
                                                                            <Button
                                                                                variant='info'
                                                                                href={`/app/${router.query.id}/social/${router.query.grpId}/post/${m.postId}`}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </div>
                                                                        {/* <div>
                                                                            <Button variant='secondary' href={`/${router.query.id}/social/${router.query.grpId}/post/master/${m.socialPostCode}`}>Edit Master</Button>
                                                                        </div> */}
                                                                        <div>
                                                                            <Button
                                                                                variant='warning'
                                                                                id={m.postId}
                                                                                onClick={onClickRepost}
                                                                            >
                                                                                Repost
                                                                            </Button>
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                variant='danger'
                                                                                id={m.postId}
                                                                                onClick={handleDelete}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </div>
                                                                        <div className='social-post-background'>
                                                                            <Icon name={provider.iconName} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col sm={6} md={5}>
                                                                <div>{iden ? iden.displayName : ''}</div>
                                                                <div>{m.message}</div>
                                                                {m.media && m.media.url &&
                                                                    <div>
                                                                        <img src={m.media.url} width='150' height='150' alt={m.mediaUrl} title={m.title} className='img-thumbnail' />
                                                                    </div>
                                                                }
                                                                <div>
                                                                    {m.mediaUrl}
                                                                </div>
                                                                <div>Scheduled Post Date <FontAwesomeIcon icon={faClock} /> {formatDate(getLocalDateTime(m.scheduledPostDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), 'dd-MMM-yyyy HH:mm')}</div> {/* eslint-disable-line quotes */}
                                                                <div>Error Date <FontAwesomeIcon icon={faClock} /> {formatDate(getLocalDateTime(m.ErrorDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), 'dd-MMM-yyyy HH:mm')}</div> {/* eslint-disable-line quotes */}
                                                            </Col>
                                                            <Col sm={3} md={4}>
                                                                {renderErrorInfo(m)}
                                                            </Col>
                                                            <Col sm={2} md={2}>
                                                                <Row>
                                                                    <Col>
                                                                        <Button variant='info' href={`/app/${router.query.id}/social/${router.query.grpId}/post/${m.postId}`}>Edit</Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Button variant='warning' id={provider.id} onClick={onClickAddIdentity}>Update Scope</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        {!iden ?
                                                            <Row>
                                                                <Col>
                                                                    <Alert variant='danger'>
                                                                        <Alert.Heading>Unknown or invalid identity</Alert.Heading>
                                                                        <div>The associated social identity no longer exists.</div>
                                                                    </Alert>
                                                                </Col>
                                                            </Row>
                                                            :
                                                            ''
                                                        }
                                                    </Col>
                                                </Row>
                                            );
                                            // } else {
                                            //     console.log('missing or invalid identity!!!!!');
                                            //     return '';
                                            // }
                                        } else {
                                            return '';
                                        }
                                    })}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <div className='App'>
            {pageLoading ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    <Row>
                        <Col sm={12} md={8}>
                            <div className='page-header'>
                                <h1><FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;Posts Needing Attention</h1>
                            </div>
                        </Col>
                    </Row>
                    {renderPostList(posts)}
                </Container>
            }
        </div>
    );
}
