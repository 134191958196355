import Home from '../../app/containers/Home.js';
import { Dashboard } from '../../app/containers/Application/Dashboard.js';
import Settings from '../../app/containers/Application/Settings.js';
import { SocialGroups } from '../../app/containers/Application/SocialGroups.js';
import { SocialGroup } from '../../app/containers/Application/SocialGroup.js';
import { NewApp } from '../../app/containers/NewApp/NewApp.js';
import Billing from '../../app/containers/Application/Billing.js';
import { NewMember } from '../../app/containers/Application/NewMember.js';
import { CalendarDashboard as Calendar } from '../../app/containers/Social/Calendar.js';
import { Attention } from '../../app/containers/Application/Attention.js';
import EditPost from '../../app/containers/Social/EditPost.js';
import EditMasterPost from '../../app/containers/Social/EditMasterPost.js';

export const routes = [
    {
        path: '/new',
        exact: true,
        authenticated: true,
        component: NewApp
    },
    {
        path: '/:id/social/:grpId/calendar',
        exact: true,
        authenticated: true,
        component: Calendar
    },
    {
        path: '/:id/social/:grpId/post/attention',
        exact: true,
        authenticated: true,
        component: Attention
    },
    {
        path: '/:id/social/:grpId/post/master/:postId',
        exact: true,
        authenticated: true,
        component: EditMasterPost
    },
    {
        path: '/:id/social/:grpId/post/:postId',
        exact: true,
        authenticated: true,
        component: EditPost
    },
    {
        path: '/:id/social/list',
        exact: true,
        authenticated: true,
        component: SocialGroups
    },
    {
        path: '/:id/social/:grpId',
        exact: true,
        authenticated: true,
        component: SocialGroup
    },
    {
        path: '/:id/billing',
        exact: true,
        authenticated: true,
        component: Billing
    },
    {
        path: '/:id/dashboard',
        exact: true,
        authenticated: true,
        component: Dashboard
    },
    {
        path: '/:id/member/request/:token',
        exact: true,
        authenticated: true,
        component: NewMember
    },
    {
        path: '/:id',
        exact: true,
        authenticated: true,
        component: Settings
    },
    {
        path: '/',
        exact: true,
        authenticated: true,
        component: Home
    }
    // {
    //     path: '../admin',
    //     exact: true,
    //     authenticated: true,
    //     component: Admin
    // }
];