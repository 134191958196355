import React, { useState, useEffect, Suspense } from 'react';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';
import { getApplicationDetails } from '../../libs/Api/application';
import { getPostsByScheduledDate, getPostsByDate, getPostsByStatus, createBasePost, deletePost, copyPost, savePost } from '../../libs/Api/posts';
import { Loading } from '../../../components/Loading';
import { CalendarIndex } from '../../components/Social/Calendar/Index';
import { PostsList } from '../../components/Social/List';
import { Week as ByWeek } from '../../components/Social/Calendar/Week';
import { useRouter, useToggle } from '../../../assets/scripts/hooksLib';
import { parseDateTime, getDayOfMonth, getLocalDateTime, isValidDateTime, setDateTime, formatDate, addTimezoneOffset, setLocalWeek } from '../../../assets/scripts/dateLib';
import { Calendar } from '../../../assets/scripts/calendarLib';
import { isNumber, nearestAncestorWithClassname, nearestAncestorWithHref, nearestAncestorWithId, adjustHeader, scrollTop, transformListToMultidimensionalArray, addPostToMultidimensionalArray, removePostFromMultidimensionalArray } from '../../../assets/scripts/utilsLib';
import { onError } from '../../../assets/scripts/errorLib';
import config from '../../../config';
import '../../../assets/styles/calendar.scss';

const RescheduleModal = React.lazy(() => import('../../components/Social/Calendar/RescheduleModal'));

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

/**
 * Calendar page
 * @returns {HTMLElement} html for calendar block
 */
export function CalendarDashboard() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState(null);
    const [socialGroup, setSocialGroup] = useState(null);
    const [calendarDate, setCalendarDate] = useState(new Calendar(new Date().getFullYear(), new Date().getMonth(), 1));
    const [weekDate, setWeekDate] = useState(new Calendar(new Date().getFullYear(), new Date().getMonth(), getDayOfMonth()));
    const [canEdit, setCanEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pill, setPill] = useState('month');
    const [tab, setTab] = useState('posted'); // eslint-disable-line no-unused-vars
    const [showRescheduleModal, setShowRescheduleModal] = useToggle(false);
    const [calendarMatrix, setCalendarMatrix] = useState([]);
    const [isMoving, setIsMoving] = useToggle(false);
    const [listTab, setListTab] = useState('POSTED');

    const totalEffects = 2;
    let loadedEffects = 0;

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // populate the social group object
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                switch (router.location.hash) {
                case '#list':
                    setPill('list');
                    break;
                case '#week':
                    setPill('week');
                    break;
                default:
                    setTab('month');
                    break;
                }

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setSocialGroup(body.socialGroups.find(sg => {
                        return sg.id === router.query.grpId;
                    }));

                    // make sure we have some groups and identities to work with
                    const identities = body.socialGroups.reduce((total, current) => {
                        return total + (current.socialGroupIdentitys ? current.socialGroupIdentitys.length : 0);
                    }, 0);
                    setCanEdit(body.Enabled && identities > 0);
                }
            } catch (e) {
                console.error(e);
                if (e.response && e.response.status === 404) {
                    //console.log(e.response);
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    onError(e);
                }
            } finally {
                loadedEffects++;

                if (loadedEffects >= totalEffects) {
                    setPageLoading(false);
                }
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setPageLoading, setCanEdit, loadedEffects]);

    // populate the posts for social group calendar
    useEffect(() => {
        let isMounted = true,
            mergedSet = [];

        const onLoad = async () => {
            // dates must be in the user's local timezone
            const now = new Date();
            let date = `${now.getFullYear()}-${now.getMonth() + 1 < 10 ? '0' + String(now.getMonth() + 1) : now.getMonth() + 1}`,
                tempDate = new Calendar();
            if (router.query.month && isNumber(router.query.month)
                && router.query.year && isNumber(router.query.year)) {
                const month = Math.abs(router.query.month),
                    year = Math.abs(router.query.year);
                date = `${year}-${month < 10 ? '0' + String(month) : month}`;
                tempDate = new Calendar(year, router.query.month - 1, 1)
                setCalendarDate(tempDate);


            } else if (router.query.week && isNumber(router.query.week)) {
                const week = Math.abs(router.query.week);
                const localDate = setLocalWeek(now, week);

                date = `${localDate.getFullYear()}-${localDate.getMonth() + 1 < 10 ? '0' + String(localDate.getMonth() + 1) : localDate.getMonth() + 1}`;

                setWeekDate(new Calendar(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
                tempDate = new Calendar(localDate.getFullYear(), localDate.getMonth(), 1);
                setCalendarDate(new Calendar(localDate.getFullYear(), localDate.getMonth(), 1));
            } else {
                tempDate = new Calendar(now.getFullYear(), now.getMonth(), 1)
                setCalendarDate(tempDate);
            }

            try {
                // scheduled posts
                const { status, message, body } = await getPostsByScheduledDate(router.query.id, router.query.grpId, date)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    mergedSet = [...body.Items];
                }

                // previously posted posts
                const postedPosts = await getPostsByDate(router.query.id, router.query.grpId, date)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (postedPosts.status !== 'OK') {
                    onError(postedPosts.message);
                } else {
                    mergedSet = mergedSet.concat([...postedPosts.body.Items]);
                }

                // draft posts
                const resp = await getPostsByStatus(router.query.id, router.query.grpId, config.postStatus.DRAFT, 'addedAt')
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (resp.status !== 'OK') {
                    onError(resp.message);
                } else {
                    mergedSet = mergedSet.concat([...resp.body.Items]);

                    // remove duplicates due to a draft having a scheduledPostDate (which resultes in adding the post two times)
                    mergedSet = mergedSet.filter((v, i, a) => a.findIndex(t => t.postId === v.postId) === i); // https://stackoverflow.com/a/56757215
                }

                // sort the array by their dates
                mergedSet.sort((a, b) => parseDateTime(a.postDate || a.scheduledPostDate || a.addedAt).getTime() - parseDateTime(a.postDate || b.scheduledPostDate || b.addedAt).getTime());

                setCalendarMatrix(transformListToMultidimensionalArray(tempDate, mergedSet));
                setPosts(mergedSet);
            } catch (e) {
                console.error(e);
                if (e.response && e.response.status === 404) {
                    //console.log(e.response);
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    onError(e);
                }
            } finally {
                loadedEffects++;

                if (loadedEffects >= totalEffects) {
                    setPageLoading(false);
                }
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setCalendarDate, setPosts, setWeekDate, setCalendarMatrix, loadedEffects]);

    /**
     * Creates a new post item and redirect to the edit page
     * @param {MouseEvent} e mouse click
     * @returns {null} redirect to the post edit page
     */
    const handleCreatePost = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        let query = `?ref=${encodeURIComponent(`${router.pathname}${router.location.search}${router.location.hash}`)}`;
        const node = nearestAncestorWithClassname(e.target, ['calendar-day', 'btn-primary']);

        query = node && node.dataset && node.dataset.date ? `${query}&date=${node.dataset.date}` : query;

        try {
            const { status, message, body } = await createBasePost(router.query.id, router.query.grpId)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                scrollTop();
                // redirect to post edit page
                router.history(`/${body.applicationId}/social/${body.socialGroupId}/post/master/${body.socialPostCode}${query}`);
            }
        } catch (err) {
            setIsLoading(false);
            onError(err);
        }
    };

    /**
     * Delete's the post from the database
     * @param {MouseEvent} e button click event
     * @returns {void} refreshes the state
     */
    const handleDelete = async (e) => {
        e.preventDefault();

        // disable editing
        if (!canEdit) {
            return;
        }

        const confirmed = window.confirm(
            'Are you sure you want to delete this Post?'
        );

        if (!confirmed) {
            return;
        }

        const node = nearestAncestorWithId(e.target),
            postId = node && node.id;

        try {
            const { status, message } = await deletePost(router.query.id, router.query.grpId, postId)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // clean up the deleted post
                let postState = [...posts],
                    postIndex = postState.findIndex(cbi => {
                        return cbi.postId === postId;
                    });

                if (postIndex > -1) {
                    // remove post from calendar matrix
                    setCalendarMatrix(removePostFromMultidimensionalArray(calendarMatrix, postState.find(p => {
                        return p.postId === postId;
                    })));

                    // remove the post from the posts state
                    postState.splice(postIndex, 1);
                    setPosts(postState);
                }
            }
        } catch (err) {
            onError(err);
        }
    };

    /**
     * Sets the current tab & navigation based on the selection
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onTabSelect = (e) => {
        // router.push(`#${e}`);
        router.history(`#${e}`);
        setPill(e);
    };

    /**
     * onChange event for the text input to update the application and refreshes the state
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChange = (e) => {
        // const field = e.target.id;
        // const val = e.target.value;
        console.log(e);
        // TODO: what state are we updating here?

        // setPost(post => {
        //     return {...post, [field]: val}
        // });
    };

    /**
     * Display the master post edit form
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onClickEdit = (e) => {
        e.preventDefault();

        const node = nearestAncestorWithHref(e.target),
            postId = node && node.id;

        let query = `?ref=${encodeURIComponent(`${router.pathname}${router.location.search}${router.location.hash}`)}`;

        router.history(`/${router.query.id}/social/${router.query.grpId}/post/master/${postId}${query}`);

        scrollTop();
    };

    /**
     * Reschedules the post's scheduled post date; triggered by an onDrag/onDrop event
     * @param {number} targetWeek week of month the post is to move to (0-5)
     * @param {number} targetDay day of week the post is to move to (0-6)
     * @param {object} item post item details
     * @returns {void}
     */
    const onMoveReschedulePost = async (targetWeek, targetDay, item) => {
        const targetDate = calendarMatrix[`${targetWeek}`].days[`${targetDay}`],
            sourceDay = item.day > 6 ? 0 : item.day < 0 ? 6 : item.day,
            sourceWeek = item.week,
            sourceDate = calendarMatrix[`${sourceWeek}`].days[`${sourceDay}`],
            prevState = [...calendarMatrix];

        // start loading indicator
        setIsMoving(true);

        // console.log(`Moving ${item.postId} from [${sourceWeek}][${sourceDay}] to [${targetWeek}][${targetDay}]`);

        if (!sourceDate) {
            // console.log('Something went wrong retrieving the source date block');
            setIsMoving(false);
            return;
        }

        if (targetWeek === sourceWeek &&
            targetDay === sourceDay) {
            // console.log('Ignore moving from-to the same date');
            setIsMoving(false);
            return;
        }

        // get the post's index in the array
        const sourceItemIndex = sourceDate.posts.findIndex(p => {
            return p.postId === item.postId;
        });

        if (sourceItemIndex < 0) {
            // console.log('Source item was not found');
            setIsMoving(false);
            return;
        }

        // get the post from the date collection
        const sourceItem = sourceDate.posts[`${sourceItemIndex}`];

        if (!sourceItem || !posts.some(p => { return p.postId === sourceItem.postId })) {
            // console.log('Could not find source item in posts list');
            setIsMoving(false);
            return;
        }

        const timeZoneOffset = new Date().getTimezoneOffset() / 60,
            sourceScheduledDate = sourceItem.scheduledPostDate ? getLocalDateTime(sourceItem.scheduledPostDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : calendarMatrix[`${targetWeek}`].days[`${targetDay}`].day; // eslint-disable-line quotes

        // get the separate time and date values
        sourceItem.scheduledPostTime = formatDate(sourceScheduledDate, 'HH:mm'); // get original post time
        sourceItem.scheduledPostDate = formatDate(calendarMatrix[`${targetWeek}`].days[`${targetDay}`].day, 'dd-MMM-yyyy'); // get the new post date

        // reset the post date to GMT time
        sourceItem.scheduledPostDate = formatDate(addTimezoneOffset(setDateTime(sourceItem.scheduledPostTime, sourceItem.scheduledPostDate), timeZoneOffset), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); // eslint-disable-line quotes

        // update the calendarMatrix
        targetDate.posts.push(sourceDate.posts[`${sourceItemIndex}`]);
        sourceDate.posts.splice(sourceItemIndex, 1);

        const nextState = [...calendarMatrix];

        setCalendarMatrix(nextState);

        // save post to database
        let x = {
            ...sourceItem,
            'scheduledPostDate': sourceItem.scheduledPostDate,
            'activityType': config.activityType.MEDIA
        };

        try {

            const { status, message, body } = await savePost(sourceItem.applicationId, sourceItem.socialGroupId, sourceItem.postId, x)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // update the state with the rescheduled post
                let postIndex = posts.findIndex(p => {
                        return p.postId === sourceItem.postId;
                    }),
                    nextState = [...posts];

                nextState[`${postIndex}`] = body;

                setPosts(nextState);
                setPost(null);
            }
        } catch (err) {
            setCalendarMatrix([...prevState]);
            onError(err);
        } finally {
            // end loading
            setIsMoving(false);
        }
    };

    /**
     * Opens the reschedule post modal
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onClickShowReschedulePost = (e) => {
        e.preventDefault();

        const node = nearestAncestorWithId(e.target),
            postId = node && node.id;

        if (postId) {
            const nextState = posts.find(p => {
                return p.postId === postId;
            });

            // split scheduledPostTime out from scheduledPostDate
            const scheduledDate = getLocalDateTime(nextState.scheduledPostDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); // eslint-disable-line quotes

            // nextState.scheduledPostDate = formatDate(scheduledDate, 'dd-MMM-yyyy');
            nextState.scheduledPostTime = formatDate(scheduledDate, 'HH:mm');
            nextState.scheduledDate = scheduledDate;

            setPost(nextState);
            setShowRescheduleModal(true);
        }
    };

    /**
     * onChange event for the Scheduled Date text input
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChangeScheduledPostDate = (e) => {
        if (e) {
            const val = e; // Fri Aug 28 2020 11:54:35 GMT-0500 (Central Daylight Time)
            let parsedDate = null;

            if (isValidDateTime(new Date(val))) {
                parsedDate = setDateTime(post.scheduledDate, new Date(val));
                setPost(post => {
                    return {
                        ...post,
                        'scheduledPostDate': formatDate(parsedDate, 'dd-MMM-yyyy'),
                        'scheduledDate': parsedDate
                    }
                });
            }
        }
    };

    /**
     * onChange event for the Scheduled Time text input
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChangeScheduledPostTime = (e) => {
        if (e) {
            const val = e; // Fri Aug 28 2020 11:54:35 GMT-0500 (Central Daylight Time)
            let parsedDate = null;

            if (isValidDateTime(new Date(val))) {
                parsedDate = new Date(val);
                setPost(post => {
                    return {
                        ...post,
                        'scheduledPostTime': formatDate(parsedDate, 'HH:mm'),
                        'scheduledDate': setDateTime(parsedDate, post.scheduledDate)
                    }
                });
            }
        }
    };

    /**
     * Create a duplicate post
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const handleReschedulePost = async (e) => {
        e.preventDefault();

        // disable editing
        if (!canEdit) {
            return;
        }

        const confirmed = window.confirm(
            'Do you want to move this Post?'
        );

        if (!confirmed || !post) {
            return;
        }

        const timeZoneOffset = new Date().getTimezoneOffset() / 60,
            scheduledPostDate = formatDate(addTimezoneOffset(setDateTime(post.scheduledPostTime, post.scheduledPostDate), timeZoneOffset), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); // eslint-disable-line quotes

        let x = {
            ...post,
            'scheduledPostDate': scheduledPostDate,
            'activityType': config.activityType.MEDIA,
            'postStatus': config.postStatus.SCHEDULED
        };

        try {

            const { status, message, body } = await savePost(router.query.id, router.query.grpId, post.postId, x)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // update the state with the rescheduled post
                let postIndex = posts.findIndex(p => {
                        return p.postId === post.postId;
                    }),
                    nextState = [...posts];

                nextState[`${postIndex}`] = body;
                // console.log(nextState);
                setPosts(nextState);
                setPost(null);
                setShowRescheduleModal(false);
            }
        } catch (err) {
            onError(err);
        } finally {
            //
        }
    };

    /**
     * Close the reschedule post modal
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onRescheduleClose = (e) => {
        e.preventDefault();

        setShowRescheduleModal(false);

        setPost(null);
    };

    /**
     * Create a duplicate post
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const handleCopyPost = async (e) => {
        e.preventDefault();

        // disable editing
        if (!canEdit) {
            return;
        }

        const confirmed = window.confirm(
            'Do you want to copy this Post?'
        );

        if (!confirmed) {
            return;
        }

        const postId = e.target.id;

        try {

            const { status, message, body } = await copyPost(router.query.id, router.query.grpId, postId)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                // update the state with the copied post
                setCalendarMatrix(addPostToMultidimensionalArray(calendarMatrix, body));
                setPosts([...posts, body]);
            }
        } catch (err) {
            onError(err);
        } finally {
            //
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='App'>
                {pageLoading || !socialGroup ?
                    <Loading pageLoading={pageLoading} /> :
                    <Container>
                        <Row>
                            <Col>
                                <Tabs
                                    id='controlled-tab-example'
                                    activeKey={pill}
                                    onSelect={onTabSelect}
                                    variant='pills'
                                    className='d-flex justify-content-center'
                                >
                                    <Tab eventKey='month' title='Month'>
                                        <CalendarIndex
                                            calendarPosts={calendarMatrix}
                                            socialGroup={socialGroup}
                                            pageLoading={pageLoading}
                                            isLoading={isLoading}
                                            calendarDate={calendarDate}
                                            setCalendarDate={setCalendarDate}
                                            onChangeEdit={onChange}
                                            onClickCreatePost={handleCreatePost}
                                            onClickDeletePost={handleDelete}
                                            onClickCopy={handleCopyPost}
                                            onReschedulePost={onClickShowReschedulePost}
                                            isAllowedToPost={canEdit}
                                            canEdit={canEdit}
                                            router={router}
                                            onMoveReschedulePost={onMoveReschedulePost}
                                            isMoving={isMoving}
                                        />
                                    </Tab>
                                    <Tab eventKey='week' title='Week'>
                                        <ByWeek
                                            calendarPosts={calendarMatrix}
                                            socialGroup={socialGroup}
                                            pageLoading={pageLoading}
                                            isLoading={isLoading}

                                            weekDate={weekDate}
                                            setWeekDate={setWeekDate}

                                            onClickEdit={onClickEdit}
                                            onChangeEdit={onChange}
                                            onClickDelete={handleDelete}
                                            onClickReschedule={onClickShowReschedulePost}
                                            onClickCopy={handleCopyPost}
                                            onClickCreatePost={handleCreatePost}
                                            isAllowedToPost={canEdit}
                                            canEdit={canEdit}
                                            router={router}
                                            onMoveReschedulePost={onMoveReschedulePost}
                                            isMoving={isMoving}
                                        />
                                    </Tab>
                                    <Tab eventKey='list' title='List'>
                                        <PostsList
                                            posts={posts}
                                            socialGroup={socialGroup}
                                            pageLoading={pageLoading}
                                            isLoading={isLoading}
                                            onClickCreatePost={handleCreatePost}
                                            onClickDeletePost={handleDelete}
                                            onClickCopy={handleCopyPost}
                                            isAllowedToPost={canEdit}
                                            listTab={listTab}
                                            setListTab={setListTab}
                                            router={router}
                                        />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                        { post && post.scheduledDate ?
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <RescheduleModal
                                    calendarDate={post.scheduledDate}
                                    show={showRescheduleModal}
                                    handleClose={onRescheduleClose}

                                    onChangeScheduledPostDate={onChangeScheduledPostDate}
                                    onChangeScheduledPostTime={onChangeScheduledPostTime}

                                    onClickSave={handleReschedulePost}
                                />
                            </Suspense>
                            :
                            ''
                        }
                    </Container>
                }
            </div>
        </DndProvider>
    );
}
