import React, { ReactComponent } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faExclamationTriangle, faListAlt, faPencilAlt, faTachometerAlt, faUserFriends, faUsers, faCog, faCircle } from '@fortawesome/free-solid-svg-icons';
import { createBasePost } from '../app/libs/Api/posts';
import { withRouter } from '../assets/scripts/hooksLib';
import { getQueryParams, scrollTop } from '../assets/scripts/utilsLib';
import { onError } from '../assets/scripts/errorLib';

/**
 * Build and display the side bar navigation (https://www.w3schools.com/howto/howto_js_sidenav.asp)
 * @param {object} argv object defining properties to use in this function
 * @param {ReactComponent} argv.children child react component
 * @param {object} argv.props react properties on the parent component
 * @returns {HTMLElement} html of navigation
 */
function NavBar({ children, ...props }) {
    // console.log('***********************************');
    // console.log(props);
    // console.log('***********************************');
    // const { location, history } = props,
    const location = props.router.location,
        // history = props.history,
        matches = getQueryParams(location.pathname),
        hasAlerts = props.hasAlerts || false;

    /* https://www.w3schools.com/howto/howto_js_sidenav.asp */
    /**
     * Set the width of the side navigation to 250px and the left margin of the page content to 250px
     * @returns {void}
     */
    const openNav = () => {
        document.getElementById('mySidenav').classList.add('expandedNav');
        document.getElementById('main').classList.add('expandedMain');
    };

    /**
     * Set the width of the side navigation to the original width and the left margin of the page content to 0
     * @returns {void}
     */
    const closeNav = () => {
        document.getElementById('mySidenav').classList.remove('expandedNav');
        document.getElementById('main').classList.remove('expandedMain');
    };

    /**
     * Creates a new post item and redirect to the edit page
     * @param {MouseEvent} e mouse click
     * @returns {void} redirect to the post edit page
     */
    const onClickCreatePost = async (e) => {
        e.preventDefault();

        try {
            const { status, message, body } = await createBasePost(matches.params.id, matches.params.grpId)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                let query = `?ref=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`;

                scrollTop();
                
                // redirect to post edit page
                props.router.navigate(`/${body.applicationId}/social/${body.socialGroupId}/post/master/${body.socialPostCode}${query}`);
            }
        } catch (err) {
            onError(err);
        } finally {
            //
        }
    };

    /**
     * Build and display the UI side navigation based on current url
     * @returns {HTMLElement} html links relative to the current url
     */
    const renderLinks = () => {
        // console.log(matches);
        if (!matches
            || (// matches.pathname === '/new'
                // || matches.pathname === '/thankyou'
                // || matches.pathname === '/features'
                // || matches.pathname === '/pricing'
                // || matches.pathname === '/conditions'
                // || matches.pathname === '/privacy'
                // || matches.pathname === '/contactus'
                // || matches.pathname === '/faq'
                matches.pathname === '/app'
                || matches.pathname === '/me'
                || matches.pathname === '/admin'
                || matches.pathname.indexOf('/social/setup') > -1)) {
            return (
                <div id='main'>
                    {children}
                </div>
            );
        } else {
            return (
                <>
                    <div id='mySidenav' className='sidenav' onMouseEnter={openNav} onMouseLeave={closeNav}>
                        {matches && matches.params && matches.params.id && renderAppLinks()}
                        {matches && matches.params && matches.params.grpId && renderSocialGroupLinks()}
                        {matches && matches.params && matches.params.id && renderSettings()}
                        {renderAppLink()}
                    </div>
                    <div id='main'>
                        {children}
                    </div>
                </>
            );
        }
    };

    /**
     * Builds out a link to the user's list of applications
     * @returns {HTMLElement} html links realted to the application
     */
    const renderAppLink = () => {
        return (
            <>
                <Link id='appsLink' to={'/'} onClick={scrollTop} rel='nofollow'><nobr><FontAwesomeIcon icon={faListAlt} fixedWidth />&nbsp;Apps</nobr></Link>
            </>
        );
    };

    /**
     * Builds out the standard application links
     * @returns {HTMLElement} html links realted to the application
     */
    const renderAppLinks = () => {
        const appId = matches && matches.params ? matches.params.id : '';

        return (
            <>
                <Link id='dashboardLink' to={`/${appId}/dashboard`} className={matches.url === `/${appId}/dashboard` ? 'active' : ''} rel='nofollow'><nobr><FontAwesomeIcon icon={faTachometerAlt} fixedWidth />&nbsp;Dashboard</nobr></Link>
                <Link id='groupsList' to={`/${appId}/social/list`} className={matches.url === `/${appId}/social/list` ? 'active' : ''} onClick={scrollTop} rel='nofollow'><nobr><FontAwesomeIcon icon={faUsers} fixedWidth />&nbsp;Social Groups</nobr></Link>
            </>
        );
    };

    /**
     * Builds out a link to the current application's settings
     * @returns {HTMLElement} html links realted to the application
     */
    const renderSettings = () => {
        const appId = matches && matches.params ? matches.params.id : '';

        return (
            <>
                <Link id='settingsLink' to={`/${appId}`} className={matches.url === `/${appId}` ? 'active' : ''} rel='nofollow'><nobr><FontAwesomeIcon icon={faCog} fixedWidth />&nbsp;Settings</nobr></Link>
            </>
        );
    };

    /**
     * Builds out the side navigation menu based on current social group
     * @returns {HTMLElement} html links related to the current social group
     */
    const renderSocialGroupLinks = () => {
        return (
            <>
                <Link id='groupLink' to={`/${matches.params.id}/social/${matches.params.grpId}`} className={matches.url === `/${matches.params.id}/social/${matches.params.grpId}` ? 'active' : ''} onClick={scrollTop} rel='nofollow'><nobr><FontAwesomeIcon icon={faUserFriends} fixedWidth />&nbsp;Social Group</nobr></Link>
                <Link id='calendarLink' to={`/${matches.params.id}/social/${matches.params.grpId}/calendar`} className={matches.url === `/${matches.params.id}/social/${matches.params.grpId}/calendar` ? 'active' : ''} onClick={scrollTop} rel='nofollow'><nobr><FontAwesomeIcon icon={faCalendarAlt} fixedWidth />&nbsp;Calendar</nobr></Link>
                <Link id='attentionLink' to={`/${matches.params.id}/social/${matches.params.grpId}/post/attention`} className={matches.url === `/${matches.params.id}/social/${matches.params.grpId}/post/attention` ? 'active' : ''} onClick={scrollTop} rel='nofollow'><nobr>
                    {hasAlerts ?
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                            <FontAwesomeIcon icon={faCircle} size="xs" style={{color:'Tomato', marginRight:'2px', marginTop:'1px', fontSize:'0.35em'}} />
                        </span>
                        :
                        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                    }
                &nbsp;Attention!</nobr></Link>
                <Link id='createPostLink' to={`/${matches.params.id}/social/${matches.params.grpId}/post`} className={matches.url === `/${matches.params.id}/social/${matches.params.grpId}/post` ? 'active' : ''} onClick={onClickCreatePost} rel='nofollow'><nobr><FontAwesomeIcon icon={faPencilAlt} fixedWidth />&nbsp;Create Post</nobr></Link>
            </>
        );
    };

    return renderLinks();
}

export default withRouter(NavBar);
