import React, { useState, useEffect, Suspense, ReactComponentElement } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTools } from '@fortawesome/free-solid-svg-icons';
import { useToggle, useRouter } from '../../../assets/scripts/hooksLib';
import { getApplicationDetails, deleteApplication } from '../../libs/Api/application';
import { updateIdentity, addPageCompanyIdentity } from '../../libs/Api/socialgroup';
import { onError } from '../../../assets/scripts/errorLib';
import { Loading } from '../../../components/Loading';
import { formatDate } from '../../../assets/scripts/dateLib';
import { adjustHeader } from '../../../assets/scripts/utilsLib';
// import '../../scss/app.scss';

const Subscription = React.lazy(() => import('../../components/Application/Subscription'));
const ApplicationProvider = React.lazy(() => import('../../components/Application/ApplicationProvider'));
const Members = React.lazy(() => import('../../components/Application/Members'));
const SocialGroup = React.lazy(() => import('../../components/Application/SocialGroup'));
const ApplicationEdit = React.lazy(() => import('../../components/Application/Edit'));
const AddPageModal = React.lazy(() => import('../../components/Application/SocialGroup/Identities/AddPageModal'));

/**
 * Application settings page
 * @returns {HTMLElement} html for settings block
 */
export default function Settings() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useToggle(true);
    const [isLoading, setIsLoading] = useToggle(false);
    const [isMembersLoading, setIsMembersLoading] = useToggle(false);
    const [isGroupsLoading, setIsGroupsLoading] = useToggle(false);
    const [isAppProviderLoading, setIsAppProviderLoading] = useToggle(false);
    const [isEditingApp, setIsEditingApp] = useToggle(false);
    const [isAddingMember, setIsAddingMember] = useToggle(false);
    const [isEditingMember, setIsEditingMember] = useState(false);
    const [isAddingGroup, setIsAddingGroup] = useToggle(false);
    const [showSocialGroupIdentities, setShowSocialGroupIdentities] = useToggle(false);
    const [isAddingIdentity, setIsAddingIdentity] = useToggle(false);
    const [identityIdClicked, setIdentityIdClicked] = useState(null);
    const [isEditingGroup, setIsEditingGroup] = useState(false);
    const [isAddingProvider, setIsAddingProvider] = useToggle(false);
    const [isEditingProvider, setIsEditingProvider] = useState(false);
    const [isAddingPage, setIsAddingPage] = useState(false);
    const [isDeleting, setIsDeleting] = useToggle(false);
    const [app, setApp] = useState(null);
    const [member, setMember] = useState(null);
    const [memberRequest, setMemberRequest] = useState(null);
    const [socialGroup, setSocialGroup] = useState({});
    const [appProvider, setAppProvider] = useState({
        appKey: '',
        appSecret: '',
        config_id: ''
    });
    const [validated, setValidated] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const handleShowSocialGroupIdentities = () => {
        setShowSocialGroupIdentities(true);
    };
    const handleCloseSocialGroupIdentities = () => { setSocialGroup({}); setShowSocialGroupIdentities(false); }
    const handleShowAddIdentity = () => setIsAddingIdentity(true);
    const handleCloseAddIdentity = () => setIsAddingIdentity(false);
    const [isListLoading, setIsListLoading] = useToggle(false);
    const [showAppProviderInfo, setShowAppProviderInfo] = useState(false);
    const [showProviderInfo, setShowProviderInfo] = useToggle(false);
    const [pages, setPages] = useState(null);
    const [showPagesModal, setShowPagesModal] = useState(false);
    const [identity, setIdentity] = useState(null);
    const handleClosePagesModal = () => { setShowPagesModal(false); setPages(null); setIdentity(null); };
    const [isRefreshing, setIsRefreshing] = useToggle(false); // eslint-disable-line no-unused-vars

    // load application info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setApp(body);
                    setCanEdit(body.Enabled);

                    // show the getting started dialog window if no providers are setup
                    if (body.applicationProviders.length === 0) {
                        setShowAppProviderInfo(true);
                    }
                }

                // check if we're within the limit of total social groups
                // setIsWithinGroupsLimit(body && body.socialGroups ? body.socialGroups.length < (body.subscriptionSocialGroupLimit === 0 ? 9999 : body.subscriptionSocialGroupLimit) : false);

                // const socProfiles = body && body.socialGroups ? body.socialGroups.reduce((count, row) =>
                //     count + (Array.isArray(row.socialGroupIdentitys) ? row.socialGroupIdentitys.length : 0), 0
                // ) : 0;

                // setIsWithinProfileLimit(body && socProfiles < body.subscriptionProfileLimit);
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    onError(e);
                }
            } finally {
                setPageLoading(false);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setApp, setCanEdit, setPageLoading]);

    /**
     * Switch back and forth between read and write modes
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleAppEditMode = (e) => {
        e.preventDefault();

        isEditingApp ? setIsEditingApp(false) : setIsEditingApp(true);
        return;
    };

    /**
     * Delete's the application from the database
     * @param {MouseEvent} e button click event
     * @returns {void} redirects to the apps list page or alerts user of error
     */
    const handleDelete = async (e) => {
        e.preventDefault();

        const confirmed = window.confirm(
            'DELETING YOUR APPLICATION WILL PERMANENTLY REMOVE ALL SETTINGS, POSTS, MEMBERS, ETC.\nTHIS IS PERMANENT AND CANNOT BE REVERESED, DO YOU WISH TO CONTINUE?'
        );

        if (!confirmed) {
            return;
        }

        setIsDeleting(true);

        try {
            await deleteApplication(router.query.id)
                .catch(err => {
                    console.error(err.message);
                });
            router.history('/apps');
        } catch (err) {
            onError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    /**
     * Handles the redirect to the billing info page
     * @param {MouseEvent} e mouse click event
     * @returns {void} redirect to the billing details page
     */
    const handleBillingClick = (e) => {
        e.preventDefault();

        router.history(`/${router.query.id}/billing`);
    };

    /**
     * Builds and displays the layout for the application object or application form
     * depending on the state isEditingApp value
     * @returns {ReactComponentElement} html
     */
    const mainDetails = () => {

        return (
            <div className='settings-section border'>
                <Row className='settings-header'>
                    <Col md={11}><FontAwesomeIcon icon={faCog} /> Application Details</Col>
                    <Col md={1}>
                        <a href={`/${router.query.id}#`} onClick={toggleAppEditMode}>
                            <FontAwesomeIcon icon={faTools} />
                        </a>
                    </Col>
                </Row>
                <Row className='settings-body'>
                    <Col>
                        {isEditingApp ?
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <ApplicationEdit
                                    app={app}
                                    setApp={setApp}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    isEditingApp={isEditingApp}
                                    setIsEditingApp={setIsEditingApp}
                                    isDeleting={isDeleting}
                                    onClickDelete={handleDelete}
                                    handleClose={toggleAppEditMode}
                                />
                            </Suspense> :
                            app &&
                            <>
                                <Row>
                                    <Col className='smaller-label'>Name</Col>
                                </Row>
                                <Row>
                                    <Col className='large-field-value'>{app.name}</Col>
                                </Row>
                                <Row>
                                    <Col className='smaller-label'>Description</Col>
                                </Row>
                                <Row>
                                    <Col className='smaller-field-value'>{app.description ? app.description : '...'}</Col>
                                </Row>
                                <Row>
                                    <Col md={6} className='smaller-label'>Url</Col>
                                    <Col md={6} className='smaller-label'>Privacy Url</Col>
                                </Row>
                                <Row>
                                    <Col md={6} className='smaller-field-value'>{app.url ? app.url : '...'}</Col>
                                    <Col md={6} className='smaller-field-value'>{app.privacyUrl ? app.privacyUrl : '...'}</Col>
                                </Row>
                                <Row>
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className='smaller-x2-label'>Created by</span> <span className='smaller-field-value'>{app && app.createdBy && app.createdBy.Username ? app.createdBy.Username : app.createdBy}</span> <span className='smaller-x2-label'>on</span> <span className='smaller-field-value'>{app && formatDate(app.createdAt, 'dd-MMM-yyyy HH:mm')}</span>
                                    </Col>
                                    <Col>
                                        <span className='smaller-x2-label'>Updated by</span> <span className='smaller-field-value'>{app && app.updatedBy && app.updatedBy.Username ? app.updatedBy.Username : app.updatedBy}</span> <span className='smaller-x2-label'>on</span> <span className='smaller-field-value'>{app && formatDate(app.updatedAt, 'dd-MMM-yyyy HH:mm')}</span>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>
            </div>
        );
    };

    /**
     * Displays (or hides) the application provider modal info window
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const handleShowAppProviderInfo = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (showAppProviderInfo) {
            setShowAppProviderInfo(false);
        } else {
            setShowAppProviderInfo(true);
        }

        return false;
    };

    /**
     * Save the list of pages, companies, or boards to the given identity
     * @param {string} identityId identity id
     * @param {Array<object>} list array of pages, companies, or boards objects
     * @returns {void}
     */
    const savePagesCompaniesBoardsList = async (identityId, list) => {
        const prevApp = { ...app };

        try {
            // get the indices of the respective group and identity
            const socialGroupIndex = app.socialGroups.findIndex(sg => {
                    return sg.id === socialGroup.id;
                }),
                identityIndex = app.socialGroups[`${socialGroupIndex}`].socialGroupIdentitys.findIndex(sgi => {
                    return sgi.id === identityId;
                });

            let identityLocal = {},
                grpId = app.socialGroups[`${socialGroupIndex}`].id;

            const socialGroupsLocal = [...app.socialGroups],
                socialGroupLocal = { ...socialGroupsLocal[`${socialGroupIndex}`] },
                socialGroupIdentitysLocal = [...socialGroupLocal.socialGroupIdentitys];

            identityLocal = { ...socialGroupIdentitysLocal[`${identityIndex}`], 'albumList': list };
            socialGroupIdentitysLocal[`${identityIndex}`] = identityLocal;
            socialGroupsLocal[`${socialGroupIndex}`].socialGroupIdentitys = socialGroupIdentitysLocal;

            setApp(app => {
                return { ...app, 'socialGroups': socialGroupsLocal }
            });

            const { status, message } = await updateIdentity(app.applicationId, grpId, identityLocal)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                setApp(prevApp);
                onError(message);
            }
        } catch (err) {
            setApp(prevApp);
            onError(err);
        } finally {
            //
        }
    };

    /**
     * Adds the page or company to this social group
     * @param {string} grpId identity id
     * @param {object} identity identity to relate to the given page
     * @param {object} page page or company to add
     * @returns {void}
     */
    const savePageCompanyAsIdentity = async (grpId, identity, page) => {
        const prevApp = { ...app };

        try {
            const { status, message, body } = await addPageCompanyIdentity(app.applicationId, grpId, identity, page)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                setApp(prevApp);
                onError(message);
            } else {
                // get the indices of the respective group and identity
                const socialGroupIndex = app.socialGroups.findIndex(sg => {
                        return sg.id === grpId;
                    }),
                    socialGroupsLocal = [...app.socialGroups],
                    socialGroupLocal = { ...socialGroupsLocal[`${socialGroupIndex}`] },
                    socialGroupIdentitysLocal = [...socialGroupLocal.socialGroupIdentitys],
                    existingIdentity = socialGroupIdentitysLocal.findIndex(i => { return i.identity === body.identity });

                // check for an existing identity, if one is found then replace it in the state
                if (existingIdentity > -1) {
                    socialGroupIdentitysLocal[`${existingIdentity}`] = body;
                } else {
                    socialGroupIdentitysLocal.push(body);
                }
                socialGroupsLocal[`${socialGroupIndex}`].socialGroupIdentitys = socialGroupIdentitysLocal;

                setApp(app => {
                    return { ...app, 'socialGroups': socialGroupsLocal }
                });
                setShowPagesModal(false);
            }
        } catch (err) {
            setApp(prevApp);
            onError(err);
        } finally {
            //
        }
    };

    return (
        <div className='App'>
            {pageLoading && !app ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    <h1>{app.name} Settings</h1>
                    <Row>
                        <Col md={8}>
                            {mainDetails()}
                        </Col>
                        <Col md={4}>
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <Subscription
                                    app={app}
                                    setApp={setApp}
                                    onBillingClick={handleBillingClick}
                                />
                            </Suspense>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <Members
                                    member={member}
                                    setMember={setMember}
                                    memberRequest={memberRequest}
                                    setMemberRequest={setMemberRequest}
                                    isLoading={isMembersLoading}
                                    setIsLoading={setIsMembersLoading}
                                    isAddingMember={isAddingMember}
                                    setIsAddingMember={setIsAddingMember}
                                    canEdit={canEdit}
                                    isEditingMember={isEditingMember}
                                    setIsEditingMember={setIsEditingMember}
                                    app={app}
                                    setApp={setApp}
                                />
                            </Suspense>
                        </Col>
                        <Col md={6}>
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <ApplicationProvider
                                    appProvider={appProvider}
                                    setAppProvider={setAppProvider}
                                    isLoading={isAppProviderLoading}
                                    setIsLoading={setIsAppProviderLoading}
                                    isAddingProvider={isAddingProvider}
                                    setIsAddingProvider={setIsAddingProvider}
                                    canEdit={canEdit}
                                    isEditingProvider={isEditingProvider}
                                    setIsEditingProvider={setIsEditingProvider}
                                    app={app}
                                    setApp={setApp}
                                    validated={validated}
                                    setValidated={setValidated}
                                    showAppProviderInfo={showAppProviderInfo}
                                    toggleAppProviderInfo={handleShowAppProviderInfo}
                                    showProviderInfo={showProviderInfo}
                                    setShowProviderInfo={setShowProviderInfo}
                                />
                            </Suspense>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <SocialGroup
                                    socialGroup={socialGroup}
                                    setSocialGroup={setSocialGroup}
                                    isLoading={isGroupsLoading}
                                    setIsLoading={setIsGroupsLoading}
                                    showSocialGroupIdentities={showSocialGroupIdentities}
                                    setShowSocialGroupIdentities={setShowSocialGroupIdentities}
                                    handleShowSocialGroupIdentities={handleShowSocialGroupIdentities}
                                    handleCloseSocialGroupIdentities={handleCloseSocialGroupIdentities}
                                    isAddingIdentity={isAddingIdentity}
                                    setIsAddingIdentity={setIsAddingIdentity}
                                    handleShowAddIdentity={handleShowAddIdentity}
                                    handleCloseAddIdentity={handleCloseAddIdentity}
                                    isAddingGroup={isAddingGroup}
                                    setIsAddingGroup={setIsAddingGroup}
                                    canEdit={app.applicationProviders && app.applicationProviders.length > 0 ? canEdit : false}
                                    setCanEdit={setCanEdit}
                                    setPagesCompaniesBoards={savePagesCompaniesBoardsList}
                                    isListLoading={isListLoading}
                                    setIsListLoading={setIsListLoading}
                                    isRefreshing={isRefreshing}
                                    isEditingGroup={isEditingGroup}
                                    setIsEditingGroup={setIsEditingGroup}
                                    app={app}
                                    setApp={setApp}
                                    setShowPagesModal={setShowPagesModal}
                                    setPages={setPages}
                                    identity={identity}
                                    setIdentity={setIdentity}
                                    identityIdClicked={identityIdClicked}
                                    setIdentityIdClicked={setIdentityIdClicked}
                                />
                            </Suspense>
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <AddPageModal
                                    app={app}
                                    socialGroup={socialGroup}
                                    identity={identity}
                                    pages={pages}
                                    showPages={showPagesModal}
                                    savePageOrCompanyAsIdentity={savePageCompanyAsIdentity}
                                    handleClose={handleClosePagesModal}
                                    isAddingPage={isAddingPage}
                                    setIsAddingPage={setIsAddingPage}
                                />
                            </Suspense>
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    );
}
